<template>
  <div class="area_view">
    <TableHead title="예산 기안번호">
      <!-- <template v-slot:headRight>
        <button type="button" class="btn_secondary btn_medium"
          @click="onClickPopDraft">기안 선택</button>
      </template> -->
    </TableHead>
    <Board :disableHover="true" :dataList="[{}]" :showPagination="false">
      <template v-slot:colgroup>
        <col style="width: 239px" />
        <col />
        <col style="width: 91px" />
      </template>
      <template v-slot:tr>
        <th>예산 기안번호</th>
        <th>제목</th>
        <th />
      </template>
      <template v-slot:rows>
        <template v-for="(item, index) in [{}]">
          <tr :key="item.id">
            <td>
              <Input :value.sync="model.draftCid" />
            </td>
            <td>
              <Input :value.sync="model.draftTitle" />
            </td>
            <td>
              <button v-if="index !== 0" type="button" class="btn_fourthly btn_small">
                {{ index }}삭제
              </button>
            </td>
          </tr>
        </template>
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="3" class="td_empty">
            선택 된 기안이 없습니다.
            <div class="area_right">
              <button type="button" disabled="disabled" class="btn_fourthly btn_small">삭제</button>
            </div>
          </td>
        </tr>
      </template>
    </Board>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import Input from "@/components/common/input/Input";

import draftBudgetNumModel from "./DraftBudgetNumModel";

export default {
  name: "DraftBudgetNum",
  components: {
    TableHead,
    Board,
    Input,
  },
  props: {
    model: draftBudgetNumModel,
  },
  methods: {
    // onClickPopDraft() {
    //   this.alert( '기안 선택 미정' );
    // },
    alert(text) {
      this.$emit("alert", text);
    },
  },
};
</script>
