class CompanyInfoModel {
  constructor() {
    this.companyName = ""; // 업체명
    this.corporateNum = ""; // 사업자번호
    this.ceo = ""; // 대표이사명
    this.address = ""; // 주소
  }

  setData(companyInfo) {
    const { companyName, corporateNum, ceo, address, address2 } = companyInfo;

    if (companyName) this.companyName = companyName;
    if (corporateNum) this.corporateNum = corporateNum;
    if (ceo) this.ceo = ceo;
    if (address) this.address = address + address2;
  }

  getData() {
    const obj = {
      companyName: this.companyName,
      coporateNum: this.corporateNum,
      ceo: this.ceo,
      address: this.address,
    };

    return obj;
  }

  // getAlertMessage() {
  //   // const defaultAlertMessage = '파트너사 정보를 확인해주세요.'

  //   if( !this.companyName )  return '파트너사 업체명을 확인해주세요.';
  //   if( !this.ceo ) return '파트너사 대표이사명을 확인해주세요.';
  //   if( !this.price )  return '파트너사 투찰금액을 확인해주세요.';

  //   return null;
  // }
}

export default CompanyInfoModel;
