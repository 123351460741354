class DraftBudgetNumModel {
  constructor() {
    this.draftCid = ""; // 기안 번호
    this.draftTitle = ""; // 기안 제목
  }

  setData(objRaw) {
    if (objRaw.draftCid) this.draftCid = objRaw.draftCid;
    if (objRaw.draftTitle) this.draftTitle = objRaw.draftTitle;
  }

  getData() {
    const obj = {
      draftCid: this.draftCid,
      draftTitle: this.draftTitle,
    };

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default DraftBudgetNumModel;
