<template>
  <Fold title="추가 정보">
    <TableViewWithTitle>
      <template v-slot:body>
        <tr>
          <th>설치(수행) 장소</th>
          <td>
            <Input :value.sync="supplyInfoModel.delivery" />
          </td>
          <th>공급 목적물</th>
          <td>
            <Input :value.sync="supplyInfoModel.why" />
          </td>
        </tr>
        <tr>
          <th>계약서 첨부파일</th>
          <td>
            <FileWrite
              :canMultiple="true"
              :dataList.sync="attachFile.contractFile"
              @fileUploaded="attachFileContractFileUploaded"
              @fileRemoved="attachFileContractFileRemoved"
            />
          </td>
          <th>발주서 첨부파일</th>
          <td>
            <FileWrite
              :canMultiple="true"
              :dataList.sync="attachFile.purchaseOrderFile"
              @fileUploaded="attachFilePurchaseOrderFileUploaded"
              @fileRemoved="attachFilePurchaseOrderFileRemoved"
            />
          </td>
        </tr>

        <tr>
          <th>특기사항</th>
          <td colspan="3">
            <Textarea
              :value.sync="supplyInfoModel.specialAgreement"
              :maxLength="200"
              :isCount="true"
            />
          </td>
        </tr>
      </template>
    </TableViewWithTitle>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import FileWrite from "@/components/common/file/FileWrite";
import Textarea from "@/components/common/textarea/Textarea";

export default {
  name: "AddInfo",
  components: {
    Fold,
    TableViewWithTitle,
    Input,
    FileWrite,
    Textarea,
  },
  props: {
    supplyInfoModel: Object,
    attachFile: Object,
  },
  methods: {
    attachFileContractFileUploaded(files) {
      this.attachFile.contractFile = this.attachFile.contractFile.concat(files);
    },
    attachFileContractFileRemoved(itemToRemove) {
      this.attachFile.contractFile = this.attachFile.contractFile.filter(
        (item) => item !== itemToRemove,
      );
    },

    attachFilePurchaseOrderFileUploaded(files) {
      this.attachFile.purchaseOrderFile = this.attachFile.purchaseOrderFile.concat(files);
    },
    attachFilePurchaseOrderFileRemoved(itemToRemove) {
      this.attachFile.purchaseOrderFile = this.attachFile.purchaseOrderFile.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
