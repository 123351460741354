<template>
  <tr>
    <!-- No -->
    <td class="align_right">{{ index + 1 }}</td>
    <!-- 품명 -->
    <td>
      <div class="group_form">
        <DialougeItem :itemCode="item.itemCode">
          <template v-slot:dialogueHead>
            <Input :value.sync="item.itemName" :isDisabled="true" :maxLength="200" />
          </template>
        </DialougeItem>
        <!-- <Input v-else :value.sync="item.goodsItem" :maxLength="200" :isDisabled="true" />
        <button class="btn_search" @click="$emit('onClickGoodsNamePopup', index)">
          <span class="ico_purchase ico_search" />
        </button> -->
      </div>
    </td>
    <!-- 품목코드 -->
    <td>
      <Input :value.sync="item.displayCode" :isDisabled="true" />
    </td>
    <!-- 자산코드 -->
    <td>
      <Input :value.sync="item.assetsCode" :isDisabled="true" />
    </td>
    <!-- 단위 -->
    <td>
      <Input :dataEllip="item.unitValue" :value.sync="item.unitValue" :isDisabled="true" />
    </td>
    <!-- 수량 -->
    <td>
      <InputAutoMoney
        :data-ellip="`단가 : ${computedEllipPrice}`"
        :inputClass="'align_right'"
        :maxLength="14"
        :value.sync="item.quantity"
        :isDisabled="true"
      />
    </td>
    <!-- 예산금액 -->
    <td>
      <InputAutoMoney
        :inputClass="'align_right'"
        :value.sync="item.totalPrice"
        :isDisabled="true"
      />
    </td>
    <!-- 소비자가 -->
    <td>
      <InputAutoMoney :inputClass="'align_right'" :value.sync="item.marketPrice" />
    </td>
    <!-- 계약금액 -->
    <td>
      <InputAutoMoney
        :data-ellip="`단가 : ${computedEllipContractPrice}`"
        :inputClass="'align_right'"
        :value.sync="item.contractPrice"
      />
    </td>
    <!-- 할인율(%) -->
    <td>
      <InputRate
        :inputClass="'align_right'"
        :isDisabled="true"
        :isValid="false"
        :max="1000"
        :min="-1000"
        :toFixed="2"
        :value.sync="item.discountRate"
      />
    </td>
    <!-- 부가세 -->
    <td>
      <InputAutoMoney :inputClass="'align_right'" :value.sync="item.vat" />
    </td>
    <!-- 절감액 -->
    <td>
      <InputAutoMoney
        :inputClass="'align_right'"
        :value.sync="item.savingAmount"
        :isDisabled="true"
      />
    </td>
    <!-- 절감율(%) -->
    <td>
      <InputRate
        :inputClass="'align_right'"
        :isDisabled="true"
        :isValid="false"
        :toFixed="2"
        :value.sync="item.savingsRate"
      />
    </td>
    <!-- 품목상세 -->
    <td>
      <Input :value.sync="item.note" :data-ellip="item.note" />
    </td>
  </tr>
</template>

<script>
import DialougeItem from "@/components/common/dialouge/DialougeItem";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputRate from "@/components/common/input/InputRate";

import SelectBoxQuantityIncludeData from "@/components/common/selectBox/SelectBoxQuantityIncludeData";
import SelectBox from "@/components/common/selectBox/SelectBox";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "SupplyInfoLine",
  components: {
    DialougeItem,
    Input,
    InputAutoMoney,
    InputRate,
    SelectBoxQuantityIncludeData,
    SelectBox,
  },
  props: {
    item: Object,
    index: Number,
  },
  computed: {
    computedEllipPrice() {
      if (!this.item.price) return "";
      return addCommaForMoney(this.item.price);
    },
    computedEllipContractPrice() {
      if (!this.item.contractPrice || !this.item.quantity) return "";
      return addCommaForMoney(
        _.floor(getMoneyToNumber(this.item.contractPrice) / getMoneyToNumber(this.item.quantity)),
      );
    },
  },
  watch: {
    "item.marketPrice": function (newVal, oldVal) {
      const { contractPrice } = this.item;
      if (contractPrice) {
        //할인율 계산
        const discountRate = this.calcDiscountRate(contractPrice, newVal);
        this.item.discountRate = String(_.round(discountRate, 2));
      }
    },
    "item.contractPrice": function (newVal, oldVal) {
      const { totalPrice, marketPrice } = this.item;
      if (marketPrice) {
        //할인율 계산
        const discountRate = this.calcDiscountRate(newVal, marketPrice);
        this.item.discountRate = String(_.round(discountRate, 2));
      }
      if (totalPrice) {
        //절감액,절감율
        const savingAmount = this.calcSavings(newVal, totalPrice);
        this.item.savingAmount = String(savingAmount);
        const savingsRate = this.calcSavingsRate(savingAmount, totalPrice);
        this.item.savingsRate = String(_.round(savingsRate, 2));
      }
      //부가세 계산

      this.item.vat = this.calcVat(newVal);

      this.$emit("changePrice", this.index);
    },
    "item.vat": function (newVal, oldVal) {
      this.$emit("changePrice", this.index);
    },
  },
  methods: {
    calcDiscountRate(contractPrice, marketPrice) {
      //할인율계산
      const discountRate = (getMoneyToNumber(contractPrice) / getMoneyToNumber(marketPrice)) * 100;
      if (discountRate >= 100) {
        // this.$toast(`할인율이 100을 초과할수 없습니다.`);
        // return;
      }

      return 100 - _.round(discountRate, 2);
    },
    calcVat(contractPrice) {
      //부가세계산
      const vat = getMoneyToNumber(contractPrice) * 0.1;

      return String(_.floor(vat));
    },
    calcSavings(contractPrice, totalPrice) {
      //절감액
      const savingAmount = getMoneyToNumber(totalPrice) - getMoneyToNumber(contractPrice);
      if (savingAmount < 0) {
        this.$toast(`계약금액이 예산금액보다 클수 없습니다.`);

        return;
      }
      return savingAmount;
    },
    calcSavingsRate(savingAmount, totalPrice) {
      //절감율계산
      const savingsRate = (getMoneyToNumber(savingAmount) / getMoneyToNumber(totalPrice)) * 100;
      if (savingsRate >= 100) {
        this.$toast(`절감율이 100을 초과할수 없습니다.`);

        return;
      }
      return _.round(savingsRate, 2);
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
  & > .dialogue_comm {
    display: block;
    width: 100%;
  }
}
</style>
