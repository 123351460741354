<template>
  <TableViewWithTitle title="발주 정보">
    <template v-slot:body>
      <tr>
        <th>구매요청서 정보<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <template v-if="!isModifyMode">
            <div class="group_form">
              <button
                type="button"
                class="btn_fourthly btn_small"
                @click="$emit('onClickPurchaseRequestesSearch')"
              >
                구매요청 정보 불러오기
              </button>
            </div>
            <ConnectEditOnlyOne
              :dataList="purchaseWfDataList"
              @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
              @onClickRemove="$emit('onClickRemovePurchaseReqeust')"
            />
          </template>
          <template v-else>
            <ConnectViewOnlyOne
              :dataList="purchaseWfDataList"
              @onClickConnect="(item) => $emit('onClickConnectPurchaseWfPost', item)"
            />
          </template>
        </td>
      </tr>
      <tr v-if="type == 'estimate'">
        <th>견적서 정보</th>
        <td colspan="3" style="height: 73px">
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="$emit('onClickEstimateRequestesSearch')"
            >
              견적서 정보 불러오기
            </button>
          </div>
          <ConnectEditOnlyOne
            :dataList="estimateWfDataList"
            @onClickConnect="(item) => $emit('onClickPopEstimatePrint', item)"
            @onClickRemove="$emit('onClickRemoveEstimateReqeust')"
          />
        </td>
      </tr>
      <tr>
        <th>프로젝트코드</th>
        <td>
          <div class="group_form">
            <Input placeholder="프로젝트 코드" :isDisabled="true" :value.sync="projectCode" />
          </div>
        </td>
        <th>프로젝트명</th>
        <td>
          <div class="group_form">
            <Input placeholder="프로젝트명" :isDisabled="true" :value.sync="projectName" />
          </div>
        </td>
      </tr>
      <tr>
        <th>발주서명<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <Input
            :maxLength="MAX_LENGTH_TITLE"
            placeholder="발주서명을 입력하세요."
            :value.sync="orderInfoModel.title"
          />
        </td>
      </tr>
      <tr>
        <th>템플릿 명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <div class="group_form">
            <button
              type="button"
              class="btn_fourthly btn_small"
              @click="onClickTemplateSelectPopup"
            >
              템플릿 선택
            </button>
            <Input
              class="w282"
              :isDisabled="true"
              placeholder="발주서 템플릿을 선택해주세요"
              :value="templateName"
            />
          </div>
        </td>
        <th>
          계약기간
          <span class="ico_purchase ico_star">필수항목</span>
        </th>
        <td>
          <DateRange :dates.sync="orderInfoModel.dates" />
        </td>
      </tr>

      <tr>
        <th>계약 체결일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DatePicker :date.sync="orderInfoModel.orderDate" />
        </td>
        <th>납품기한<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DatePicker :date.sync="orderInfoModel.goodsDeadline" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import DateRange from "@/components/common/calendar/DateRange";
import DatePicker from "@/components/common/calendar/DatePicker";
import Input from "@/components/common/input/Input";
import ConnectEditOnlyOne from "@/components/common/connect/ConnectEditOnlyOne";
import ConnectViewOnlyOne from "@/components/common/connect/ConnectViewOnlyOne";

import OrderInfoModel from "./OrderInfoModel";

import { MAX_LENGTH_TITLE } from "@/constants/commonTextLength";

export default {
  name: "OrderInfo",
  components: {
    TableViewWithTitle,
    DateRange,
    DatePicker,
    Input,
    ConnectEditOnlyOne,
    ConnectViewOnlyOne,
  },
  props: {
    isModifyMode: Boolean,
    projectCode: String,
    projectName: String,
    templateName: String,
    templateId: String,
    type: String,
    orderCid: String,
    orderInfoModel: Object,
    purchaseWfDataList: Object,
    estimateWfDataList: Object || String,
    requestOrderList: Array,
  },
  computed: {
    MAX_LENGTH_TITLE() {
      return MAX_LENGTH_TITLE;
    },
    contractData() {
      let obj = {};
      obj.contractId = this.orderInfoModel.contractId;
      obj.contractName = this.orderInfoModel.contractName;
      return obj;
    },
  },
  methods: {
    onClickTemplateSelectPopup() {
      this.$emit("onClickTemplateSelectPopup");
    },
  },
};
</script>
