<template>
  <TableViewWithTitle title="입찰공고 정보">
    <template v-if="showPostCid" v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />입찰공고 선택 버튼을 통해 입찰 공고 내용을 불러 올
        수 있습니다.
      </p>
    </template>
    <template v-else v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />입찰공고 정보를 입력하지 않으면 자동으로
        생성됩니다.
      </p>
    </template>
    <template v-if="showPostCid" v-slot:headRight>
      <button type="button" class="btn_secondary btn_medium" @click="onClickPopAnnounceList">
        입찰공고 선택
      </button>
    </template>
    <template v-if="showPostCid" v-slot:body>
      <tr>
        <th>입찰 공고번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view_l w270">{{
              postCid || "공고를 선택하거나 번호를 생성하세요."
            }}</span>
            <button type="button" class="btn_fourthly btn_small" @click="onClickCreatePostCid">
              공고 번호 생성
            </button>
          </div>
        </td>
      </tr>
      <tr>
        <th>입찰공고명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <Input placeholder="입찰공고명를 입력하세요." :value.sync="model.title" />
        </td>
        <th>입찰 시작일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DateTimePicker :date.sync="model.startDate" />
        </td>
      </tr>
      <tr>
        <th>투찰금액<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <InputAutoMoney placeholder="투찰금액을 입력하세요." :value.sync="model.price" />
        </td>
        <th>입찰 마감일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <DateTimePicker :date.sync="model.endDate" />
        </td>
      </tr>
      <tr>
        <th>분류<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <CategoryGroupIncludeData
            ref="category"
            :category0.sync="model.category0"
            :category1.sync="model.category1"
            :category2.sync="model.category2"
          />
        </td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <tr>
        <th>입찰공고명</th>
        <td>
          <Input class="w380" placeholder="입찰공고명를 입력하세요." :value.sync="model.title" />
        </td>
        <th>입찰 시작일</th>
        <td>
          <DateTimePicker :date.sync="model.startDate" />
        </td>
      </tr>
      <tr>
        <th>투찰금액</th>
        <td>
          <InputAutoMoney placeholder="투찰금액을 입력하세요." :value.sync="model.price" />
        </td>
        <th>입찰 마감일</th>
        <td>
          <DateTimePicker :date.sync="model.endDate" />
        </td>
      </tr>
      <tr>
        <th>분류</th>
        <td colspan="3">
          <CategoryGroupIncludeData
            ref="category"
            :category0.sync="model.category0"
            :category1.sync="model.category1"
            :category2.sync="model.category2"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import DateTimePicker from "@/components/common/calendar/DateTimePicker";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";

import AnnounceInfoModel from "./AnnounceInfoModel";

export default {
  name: "AnnounceInfoInput",
  components: {
    TableViewWithTitle,
    Input,
    DateTimePicker,
    CategoryGroupIncludeData,
    InputAutoMoney,
  },
  props: {
    postCid: String,
    model: AnnounceInfoModel,
    showPostCid: Boolean,
  },
  methods: {
    setCategoryForce(category0, category1, category2) {
      this.$refs.category.setForce(category0, category1, category2);
    },
    onClickCreatePostCid() {
      this.$emit("onClickCreatePostCid");
    },
    onClickPopAnnounceList() {
      this.$emit("onClickPopAnnounceList");
    },
  },
};
</script>
