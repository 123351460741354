<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">
          발주 등록
          <span v-if="orderCid" class="txt_view">{{ orderCid }}</span>
        </h2>
      </div>
      <div class="body_section">
        <OrderInfo
          :isModifyMode="isModifyMode"
          :orderCid="orderCid"
          :projectCode="projectCode"
          :projectName="projectName"
          :templateName="templateName"
          :templateId="templateId"
          :type="type"
          :orderInfoModel.sync="orderInfoModel"
          :purchaseWfDataList="purchaseWfOrder"
          :estimateWfDataList="estimateOrder ? estimateOrder : {}"
          :requestOrderList="requestOrderList"
          @onClickPurchaseRequestesSearch="onClickPurchaseRequestesSearch"
          @onClickEstimateRequestesSearch="onClickEstimateRequestesSearch"
          @onClickConnectPurchaseWfPost="onClickConnectPurchaseWfPost"
          @onClickRemovePurchaseReqeust="onClickRemovePurchaseReqeust"
          @onClickConnectContract="onClickConnectContract"
          @onClickConnectEstimateWfPost="onClickConnectEstimateWfPost"
          @onClickRemoveEstimateReqeust="onClickRemoveEstimateReqeust"
          @onClickPopEstimatePrint="onClickPopEstimatePrint"
          @onClickTemplateSelectPopup="onClickTemplateSelectPopup"
        />
        <ManagerInfo :managerInfoModel.sync="managerInfoModel" @onClickPopLdap="onClickPopLdap" />

        <!-- 협력사 정보 -->
        <SupplyPartnerInfo
          :contractId="contractId"
          :contractType="type"
          :model.sync="supplyPartnerInfoModel"
          @onClickPopPartnerSelect="onClickPopPartnerSelect"
        />
        <!-- 협력사 담당자 정보 -->
        <SupplyPartnerManagerInfoInput
          :contractType="type"
          :model.sync="supplyPartnerManagerInfoModel"
        />
        <!-- 품목상세 -->
        <template v-if="type !== 'renewal'">
          <!-- 일반발주일떄 사용 -->
          <SupplyInfo
            :assetTypeName="assetTypeName"
            :supplyInfoModel.sync="supplyInfoModel"
            :attachFile.sync="attachFile"
          />
        </template>
        <template v-else>
          <!-- 변경발주일떄 사용 -->
          <SupplyInfoRenewal
            :type="type"
            :assetType="assetType"
            :assetTypeName="assetTypeName"
            :supplyInfoModel.sync="supplyInfoModel"
            :totalInspectAmount="totalInspectAmount"
            :attachFile.sync="attachFile"
            @onClickGoodsNamePopup="onClickGoodsNamePopup"
          />
        </template>

        <PaymentMethod
          :paymentMethodModel.sync="paymentMethodModel"
          :attachFile.sync="attachFile"
          :supplyInfoModel.sync="supplyInfoModel"
        />

        <AddInfo :supplyInfoModel.sync="supplyInfoModel" :attachFile.sync="attachFile" />
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickPopCancel">취소</button>
      </div>
      <div class="area_right">
        <template v-if="purchaseWfOrder.requestCid">
          <template v-if="type !== 'renewal'">
            <button v-if="status" class="btn_fourthly btn_large" @click="onClickPreviewPop">
              미리보기
            </button>
            <button
              v-if="status === '' || status === 'T'"
              type="button"
              class="btn_primary btn_large"
              @click="onClickSaveTemp"
            >
              임시저장
            </button>
            <button
              v-if="status === '' || status === 'T'"
              type="button"
              class="btn_primary btn_large"
              @click="onClickPopPublish"
            >
              발주요청
            </button>
          </template>
          <template v-else>
            <!-- <button type="button" class="btn_fourthly btn_large" @click="onClickPreviewPop">
              미리보기
            </button> -->
            <!-- <button
              v-if="status === '' || status === 'T'"
              type="button"
              class="btn_primary btn_large"
              @click="onClickSaveTemp"
            >
              임시저장
            </button> -->
            <button
              v-if="status === '' || status === 'T'"
              type="button"
              class="btn_primary btn_large"
              @click="onClickRenewalConfirm"
            >
              변경발주 요청
            </button>
          </template>
        </template>
      </div>
    </Sticky>
    <template v-slot:popup>
      <TemplateSelectPopup
        v-if="isPopTemplateSelect"
        :beforePage="'발주'"
        templateGubun="발주서"
        :isSelectBoxDisabled="true"
        @onClickSubmit="onClickSubmitPopTemplateSelect"
        @onClickClose="onClickClosePopTemplateSelect"
      />
      <OrderPrintPopup
        v-if="isPrintPopup"
        adminCompanyName="LIG 시스템"
        :orderNumber="orderCid"
        :orderPrintInfoModel="orderPrintInfoModel"
        :orderPrintTotalModel="orderPrintTotalModel"
        :paymentInfoModel="paymentInfoModel"
        :supplyInfoModelList="supplyInfoModelList"
        :orderPrintOath="orderPrintOath"
        :title="orderInfoModel.title"
        :isPrintAble="isPrintAble"
        :currency="currency"
        :attachFileList="attachFileList"
        @onClickClose="onClickOrderPreviewClose"
      />
      <OrderAnnouncePopup
        v-if="isOrderAnnouncePopup"
        @onClickClose="onClickClose"
        @onClickSelect="onClickSelect"
      />
      <AlertPopup
        v-if="isPopCancel"
        alertText="발주서 발행을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelInPopCancel"
        @onClickPrimary="onClickConfirmInPopCancel"
      />
      <AlertPopup
        v-if="isPopPublish"
        alertText="해당 발주서를 발행 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelInPopPublish"
        @onClickPrimary="onClickConfirmInPopPublish"
      />
      <DirectorLdapPopup
        v-if="isPopLdap"
        @onClickSelect="onClickSelectLdap"
        @onClickClose="onClickClosePopLdap"
        @alert="alert"
      />
      <TemplatePreviewPopup
        v-if="isTemplatePopup"
        :previewId="previewId"
        @onClickClose="onClickClosePreviewPopup"
      />
      <ContractPartnerPopup
        v-if="isPopPartnerSelect"
        @onClickClose="onClickClosePopParnterSelect"
        @onClickAdd="onClickAddPartner"
      />
      <PurchaseRequestsPopup
        v-if="isPopPurchaseRequests"
        :purchaseWfDataList.sync="purchaseWfOrder"
        :type="'O'"
        @onClickClose="onClickClosePopPurchaseRequests"
        @setPurchaseContract="setPurchaseContract"
      />
      <PurchaseRequestDetailPopup
        v-if="isPopRequestPost"
        :requestCid="requestCidSelected"
        @onClickClose="onClickCloseRequestPost"
        @alert="onAlert"
      />
      <PopGoodsNameSearch
        v-if="isPopGoodsName"
        :selectedGoodsName="selectedGoodsName"
        @onClickClose="onClickCloseGoodsNamePopup"
        @onClickComplete="onClickCompleteGoodsNamePopup"
      />

      <EstimateRequestsPopup
        v-if="isPopEstimateRequests"
        :estimateWfDataList.sync="estimateOrder"
        :type="'S'"
        @onClickClose="onClickClosePopEstimateRequests"
        @setEstimateContract="setEstimateContract"
      />
      <EstimatePrintPopup
        v-if="isPopEstimatePrint"
        :estimateNo="estimatePrint ? estimatePrint.quotationNum : null"
        :onlyCloseButton="true"
        @onClickClose="onClickClosePopEstimatePrint"
      />
      <OrderReasonPopup
        v-if="isOrderReasonPopup"
        :changeReason.sync="changeReason"
        @onClickReasonClose="onClickReasonClose"
        @onClickReasonSubmit="onClickReasonSubmit"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

import OrderInfo from "@/components/admin/order/write/OrderInfo";
import PurchaseRequestConnectWrite from "@/components/shared/purchaseRequest/PurchaseRequestConnectWrite";
import OrderMemo from "@/components/admin/order/write/OrderMemo";

// import PurchaseJiraConnectWrite from '@/components/shared/purchaseJira/PurchaseJiraConnectWrite'

import AnnounceInfoInput from "@/components/admin/order/write/announceInfo/AnnounceInfoInput";
import AnnounceInfo from "@/components/admin/order/write/announceInfo/AnnounceInfo";

import ManagerInfo from "@/components/admin/order/write/ManagerInfo";

// import SupplyPartnerInfoInput from '@/components/admin/order/write/supplyPartnerInfo/SupplyPartnerInfoInput';
import SupplyPartnerInfo from "@/components/admin/order/write/supplyPartnerInfo/SupplyPartnerInfo";

import SupplyPartnerManagerInfoInput from "@/components/admin/order/write/supplyPartnerManagerInfo/SupplyPartnerManagerInfoInput";
import SupplyPartnerManagerInfo from "@/components/admin/order/write/supplyPartnerManagerInfo/SupplyPartnerManagerInfo";

import SupplyInfo from "@/components/admin/order/write/SupplyInfo";
import SupplyInfoRenewal from "@/components/admin/order/write/SupplyInfoRenewal";
import PaymentInfo from "@/components/admin/order/write/PaymentInfo";

import PaymentMethod from "@/components/admin/order/write/PaymentMethod";

import AddInfo from "@/components/admin/order/write/AddInfo";

import FileUpload from "@/components/admin/order/write/FileUpload";
import DraftBudgetNum from "@/components/admin/order/write/DraftBudgetNum";

import Sticky from "@/components/layout/content/Sticky.vue";
import TemplateSelectPopup from "@/components/shared/template/TemplateSelectPopup";
import OrderAnnouncePopup from "@/components/admin/order/popup/OrderAnnouncePopup";
import OrderPrintPopup from "@/components/admin/order/popup/OrderPrintPopup";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import DirectorLdapPopup from "@/components/admin/system/director/popup/DirectorLdapPopup";
import TemplatePreviewPopup from "@/components/shared/template/TemplatePreviewPopup.vue";
import ContractPartnerPopup from "@/components/admin/contract/popup/ContractPartnerPopup";
import PurchaseRequestsPopup from "@/components/shared/purchaseRequestPopup/PurchaseRequestsPopup";
import PurchaseRequestDetailPopup from "@/components/admin/purchaseRequest/popup/PurchaseRequestDetailPopup";

import EstimateRequestsPopup from "@/components/shared/estimateRequestPopup/EstimateRequestsPopup";
import EstimatePrintPopup from "@/components/admin/estimate/popup/EstimatePrintPopup";
import OrderReasonPopup from "@/components/admin/order/popup/OrderReasonPopup.vue";

import PopGoodsNameSearch from "@/components/admin/draft/write/popup/PopGoodsNameSearch.vue";

import OrderInfoModel from "@/components/admin/order/write/OrderInfoModel";
import CompanyInfoModel from "@/components/admin/order/write/CompanyInfoModel";
import AnnounceInfoModel from "@/components/admin/order/write/announceInfo/AnnounceInfoModel";
import ManagerInfoModel from "@/components/admin/order/write/ManagerInfoModel";
import SupplyPartnerInfoModel from "@/components/admin/order/write/supplyPartnerInfo/SupplyPartnerInfoModel";
import SupplyPartnerManagerInfoModel from "@/components/admin/order/write/supplyPartnerManagerInfo/SupplyPartnerManagerInfoModel";

import SupplyInfoModel from "@/components/admin/order/write/SupplyInfoModel";

import PaymentInfoModel from "@/components/admin/order/write/PaymentInfoModel";
import PaymentMethodModel from "@/components/admin/order/write/PaymentMethodModel";
import PaymentMethodRateLineModel from "@/components/admin/order/write/PaymentMethodRateLineModel";

import DraftBudgetNumModel from "@/components/admin/order/write/DraftBudgetNumModel";

import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { dateToStringHasDiv } from "@/utils/dateUtils";
import { addCommaForMoney, getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";

import SupplyInfoItemModel from "../../../components/admin/order/write/SupplyInfoItemModel";

import { ALERT_ACTION } from "@/store/modules/alert/action";

import SupplyFinalPriceModel from "@/components/admin/order/write/SupplyFinalPriceModel";
import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
export default {
  name: "OrderWrite",
  components: {
    PageWithLayout,
    TableViewWithTitle,
    Input,

    OrderInfo,
    PurchaseRequestConnectWrite,
    OrderMemo,

    AnnounceInfoInput,
    AnnounceInfo,

    // PurchaseJiraConnectWrite,

    ManagerInfo,

    //SupplyPartnerInfoInput,
    SupplyPartnerInfo,

    SupplyPartnerManagerInfoInput,
    SupplyPartnerManagerInfo,

    SupplyInfo,
    SupplyInfoRenewal,
    PaymentInfo,

    PaymentMethod,

    AddInfo,
    FileUpload,
    DraftBudgetNum,
    Sticky,
    TemplateSelectPopup,
    OrderPrintPopup,
    OrderAnnouncePopup,
    AlertPopup,
    DirectorLdapPopup,
    TemplatePreviewPopup,
    ContractPartnerPopup,
    PurchaseRequestsPopup,
    PurchaseRequestDetailPopup,
    EstimateRequestsPopup,
    EstimatePrintPopup,
    OrderReasonPopup,

    PopGoodsNameSearch,
  },
  mixins: [PageMixin],
  data() {
    return {
      isModifyMode: false,
      projectCode: "",
      projectName: "",
      templateName: "",
      templateId: "",
      assetType: "",
      assetTypeName: "",
      // quantity: 'EA',
      currency: "KRW",
      vat: "별도",
      type: null,
      attachFile: {
        electronicDocumentFile: [], //전자서명파일
        purchaseOrderFile: [], //발주서
        estimateFile: [], //견적서
        contractFile: [], //계약서
        etcAttachFile: [], //기타파일
        advancePaymentSuretyAttachFile: [], //선급금이행증서
        contractSuretyAttachFile: [], //계약이행증서
        defectSuretyAttachFile: [], //하자이행증권
      },
      requestCid: "", // 구매요청서 번호.

      purchaseWfOrder: {},
      // 공고 불러와서 연결하는 경우 / 아닌 경우 뷰가 다르다.
      estimateOrder: {},
      isAnnounceConnected: false,

      // 서버에서 받은 그대로 가지고 있는다.
      postBoard: {},
      postTender: {},
      status: "",

      orderCid: "",
      contractId: "",
      orderInfoModel: new OrderInfoModel(),
      CompanyInfoModel: new CompanyInfoModel(),
      estimateNum: "",
      memo: "",

      postCid: "",
      announceInfoModel: new AnnounceInfoModel(),
      managerInfoModel: new ManagerInfoModel(),
      supplyPartnerInfoModel: new SupplyPartnerInfoModel(),
      supplyPartnerManagerInfoModel: new SupplyPartnerManagerInfoModel(),

      supplyInfoModel: new SupplyInfoModel(),

      paymentMethodModel: new PaymentMethodModel(),
      paymentMethodRateLineModel: new PaymentMethodRateLineModel(),

      paymentInfoModel: new PaymentInfoModel(),
      draftBudgetNumModel: new DraftBudgetNumModel(),

      supplyInfoModelList: [],

      note: "",

      cutOffPrice: "0",
      selectedGoodsName: {},
      changeReason: "", //변경계약 사유,
      totalInspectAmount: 0,

      isPopTemplateSelect: false,

      isOrderAnnouncePopup: false,
      isPrintPopup: false,
      isPrintAble: false,
      isPopCancel: false,
      isPopPublish: false,

      requestOrderList: [],
      isDisabledPartnerInfo: false,
      isPopLdap: false,
      selectDiffPostCid: false,
      previewId: "",
      isTemplatePopup: false,
      showPostCid: true,
      isPopPartnerSelect: false,
      isDirectPartnerInputMode: false,
      beforeWritingPage: "",

      isPopPurchaseRequests: false,

      isPopRequestPost: false,
      requestCidSelected: null,

      isPopGoodsName: false,
      supplyInfoTargetIndex: null,
      isPopProjectCode: false,
      isPopEstimateRequests: false,
      isPopEstimatePrint: false,
      isOrderReasonPopup: false,
    };
  },
  computed: {
    // 구매신청서. 네이밍 한번에 변경 예정.
    isShowPurchaseRequest() {
      return LocalStorageManager.shared.getIsShowPurchaseRequest();
    },
    // 일반,검수담당자는 미리보기만 노출 관리자는 모두 노출 구분
    isShowControlBtn() {
      const isUser = LocalStorageManager.shared.getIsRollUser();
      const isInspect = LocalStorageManager.shared.getIsInspect();
      return !isUser && !isInspect;
    },
    useTemplate() {
      // return LocalStorageManager.shared.getUseTemplate();
      return false;
    },
    postBoardCurrency() {
      if (!this.postBoard) return "KRW";
      if (!this.postBoard.currency) return "KRW";

      return this.postBoard.currency;
    },
    orderPrintInfoModel() {
      const goodsDeadline = this.orderInfoModel.goodsDeadline;
      const orderDate = this.orderInfoModel.orderDate;

      const partnerManagerMobile = this.supplyPartnerManagerInfoModel.mobile;

      const pManager = this.managerInfoModel.pManager;
      const bManager = this.managerInfoModel.bManager;

      const { sumPrice } = this.$refs.supplyInfo.getSumData();

      const ret = {
        from: {
          companyName: this.CompanyInfoModel.companyName,
          corporateNum: this.CompanyInfoModel.corporateNum,
          ceo: this.CompanyInfoModel.ceo,
          address: this.CompanyInfoModel.address,
          pManagerName: pManager.name,
          pManagerTel: pManager.tel,
          bManagerName: bManager.name,
          bManagerTel: bManager.tel.getIsValid() ? bManager.tel.getTextFull() : "",
        },

        to: {
          companyName: this.supplyPartnerInfoModel.companyName,
          ceo: this.supplyPartnerInfoModel.ceo,
          corporateNum: this.supplyPartnerInfoModel.corporateNum,
          address: this.supplyPartnerInfoModel.address,
          managerName: this.supplyPartnerManagerInfoModel.name,
          managerMobile: partnerManagerMobile.getIsValid()
            ? partnerManagerMobile.getTextFull()
            : "",
        },

        goodsDeadline: goodsDeadline ? dateToStringHasDiv(goodsDeadline) : "",
        orderDate: orderDate ? dateToStringHasDiv(orderDate) : "",

        // sumPrice: sumPrice ? addCommaForMoney( String( sumPrice ) ) : '0',
        sumPrice: sumPrice || "0",
      };

      return ret;
    },
    orderPrintTotalModel() {
      const { amount, truncationPrice, price, sumPrice } = this.$refs.supplyInfo.getSumData();

      const ret = {
        amount: amount || "0",
        truncationPrice: truncationPrice || "0",
        price: price || "0",
        sumPrice: sumPrice || "0",
      };

      return ret;
    },
    orderPrintOath() {
      const bManager = this.managerInfoModel.bManager;

      const ret = {
        from: {
          bManagerName: bManager.name,
          bManagerTel: bManager.tel.getIsValid() ? bManager.tel.getTextFull() : "",
        },
        to: {
          companyName: this.supplyPartnerInfoModel.companyName,
          corporateNum: this.supplyPartnerInfoModel.corporateNum,
          ceo: this.supplyPartnerInfoModel.ceo,
          address: this.supplyPartnerInfoModel.address,
        },
      };

      return ret;
    },
  },
  created() {
    this.orderCid = this.$route.params.id;
    const { requestCid, from } = this.$route.query;
    this.requestCid = requestCid;
    if (from) this.beforeWritingPage = from;

    this.isModifyMode = this.$route.meta.isModifyMode;

    if (this.$route.meta.isRenewalMode) {
      //변경발주 일떄
      const { orderCid, type } = this.$route.params;
      if (!this.$route.params.type) {
        this.alert("잘못된 접근입니다.", () => {
          this.$router.push({ path: this.$routerPath.ORDER_LIST });
        });
        return;
      }
      this.type = type;
      this.orderCid = orderCid;
      this.getRenewalData(this.orderCid);
    } else {
      if (this.isModifyMode) {
        this.getData(this.orderCid);
      } else {
        const { requestCid, estimateNum, type } = this.$route.query;
        if (requestCid) {
          this.requestCid = requestCid;
          this.type = type;
          this.estimateNum = estimateNum;

          switch (this.type) {
            case "estimate":
              this.getEstimateRequest();
              break;
            case "purchase":
              this.getPurchaseRequest();
              break;
          }
        }
      }
    }
  },

  mounted() {
    if (this.$route.params.purchaseRequestIdList) {
      this.requestOrderList = this.$route.params.purchaseRequestIdList;
    }
  },
  methods: {
    async getOrderInfo(data) {
      const { orderTemplateId, orderTemplateName, purchaseRequestDetail } = data;
      const {
        assetType,
        assetTypeName,
        title,
        requesterId,
        requesterName,
        requestDeptName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
        totalPrice,
        purchaseGoodsList,
        projectCode,
        projectName,
      } = purchaseRequestDetail;
      this.assetType = assetType;
      this.assetTypeName = assetTypeName;
      //this.templateName = orderTemplateName;
      //this.templateId = orderTemplateId;
      this.projectCode = projectCode;
      this.projectName = _.unescape(projectName);
      this.purchaseWfContract = _.cloneDeep(purchaseRequestDetail);
      //this.estimateContract = _.cloneDeep(estimateSearch);

      this.orderInfoModel.title = title || "";

      this.managerInfoModel.setBManager({
        requesterId,
        requesterName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
      });
      this.managerInfoModel.setIManager({
        requesterId,
        requesterName,
        requesterTel,
        requesterEmail,
        requesterPersonNum,
      });

      this.purchaseWfOrder = _.cloneDeep(purchaseRequestDetail);

      this.supplyInfoModel.setOrderGoodsList(purchaseGoodsList);
    },
    async getPurchaseRequest() {
      // 구매요청상세에서 계약관리 버튼 눌어 들어 왓을떄에 데이터 처리
      // 구매요청서 선택시 데이터 처리
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.requestCid}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.getOrderInfo(data);
    },

    async getEstimateRequest() {
      const path = `${this.$apiPath.ST_COMM_REQUEST}/${this.type}/${this.estimateNum}`;
      const result = await ApiService.shared.getData(`${path}`);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.getOrderInfo(data);
      this.getParnerData(data);

      this.estimateOrder = data.estimateSearch;
      // const { title, quotationNum } = quotationList[0];

      // this.estimateOrder = {
      //   title: title,
      //   quotationNum: quotationNum,
      // };
    },
    async getRenewalData(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.ORDER}/renewal/${id}`);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.setData(data);
    },

    async getParnerData(val) {
      const { name, companyName } = val.estimateSearch.selectionQuotation;
      const path = `${this.$apiPath.PARTNER_PERSON_LIST}?name=${name}&companyName=${companyName}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }

      this.supplyPartnerInfoModel.setData(data[0]);
      this.supplyPartnerManagerInfoModel.setData(data[0]);
    },

    // async getPurchaseRegisterList( arr ) {
    //   const result = await ApiService.shared.postData( this.$apiPath.PURCHASE_REGISTER, arr );

    //   this.requestOrderList = result.data || [];
    // },
    onClickRemove(itemToRemove) {
      this.requestOrderList = this.requestOrderList.filter((item) => item !== itemToRemove);
    },
    async getData(id) {
      const result = await ApiService.shared.getData(`${this.$apiPath.ORDER}/${id}`);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text, () => {
          this.$router.push({
            path: this.$routerPath.ORDER_LIST,
          });
        });
        return;
      } else {
        if (data.contractId) {
          this.alert("잘못된 접근입니다.", () => {
            this.$router.push({ path: this.$routerPath.ORDER_LIST });
          });
          return;
        }
      }

      this.setData(data);
    },
    setData(rawData) {
      const {
        attachFile,
        status,
        assetType,
        assetTypeName,
        projectCode,
        projectName,
        templateName,
        templateId,
        contractId,
        postTender,
        postBoard,
      } = rawData;
      this.setDataSelected(rawData);
      this.attachFile = { ...this.attachFile, ...attachFile };

      this.status = status;
      this.assetType = assetType;
      this.assetTypeName = assetTypeName;
      this.projectCode = projectCode;
      this.projectName = _.unescape(projectName);
      this.templateName = templateName;
      this.templateId = templateId;
      this.contractId = String(contractId);

      this.supplyPartnerInfoModel.setData(postTender);
      this.supplyPartnerManagerInfoModel.setData(postTender.tenderPersonList[0]);

      this.paymentMethodModel.setData(rawData.orderPayment);

      this.managerInfoModel.setData(postBoard.purchasePersonList); //담당자세팅

      this.supplyInfoModel.setData({
        orderGoodsList: rawData.orderGoodsList,
        ...rawData.orderGoodsAddInfo,
      });

      const { requestOrderList, purchaseWfOrder, estimateSearch } = rawData;

      //이하 기존
      //this.requestOrderList = requestOrderList || [];

      this.purchaseWfOrder = purchaseWfOrder || {};
      this.estimateOrder = estimateSearch || {};

      if (rawData.memo) this.memo = rawData.memo;
      if (rawData.postCid) this.postCid = rawData.postCid;
      if (rawData.goodsNote) this.note = rawData.goodsNote;

      if (rawData.truncationPrice) {
        this.cutOffPrice = rawData.truncationPrice;
      } else {
        this.cutOffPrice = "0";
      }
      if (rawData.vat) this.vat = rawData.vat;
      this.orderInfoModel.setData(rawData);

      // if (rawData.orderGoodsRequests) {
      //   this.supplyInfoModelList = rawData.orderGoodsRequests.map((item) => {
      //     const model = new SupplyInfoItemModel();

      //     // const cloned = Object.assign( {}, item, { currency: this.postBoardCurrency, quantity: this.postBoardQuantity } );
      //     const cloned = Object.assign({}, item, {
      //       currency: this.postBoardCurrency,
      //     });

      //     model.setData(cloned);
      //     return model;
      //   });
      // }

      // this.quantity = this.postBoardQuantity;
      this.currency = this.postBoardCurrency;

      this.paymentInfoModel.setData(rawData);

      this.draftBudgetNumModel.setData(rawData);

      if (rawData.totalInspectAmount) {
        this.totalInspectAmount = rawData.totalInspectAmount;
      }
    },
    setDataSelected(rawData) {
      const { postBoard, postTender, companyInfo } = rawData;

      this.postBoard = postBoard;
      this.postTender = postTender;

      this.announceInfoModel.setData(postBoard);

      if (this.$refs.announceInfoInput) {
        const { cate1, cate2, cate3 } = postBoard;
        this.$refs.announceInfoInput.setCategoryForce(cate1, cate2, cate3);
      }

      if (postBoard) {
        // this.supplyInfoModelList[0].setAmount(postBoard.amount);
        // this.supplyInfoModelList[0].setGoodsItem(postBoard.goods);

        if (postBoard.purchasePersonList) {
          this.managerInfoModel.setData(postBoard.purchasePersonList);
        }
      }

      if (companyInfo) {
        this.CompanyInfoModel.setData(companyInfo);
      }

      if (postTender) {
        this.supplyPartnerInfoModel.setData(postTender);

        // 파트너사 담당자 한명이라고 가정. 기획서에 한명 이상 등록 불가능.
        if (postTender.tenderPersonList && postTender.tenderPersonList.length > 0) {
          this.supplyPartnerManagerInfoModel.setData(postTender.tenderPersonList[0]);
        }

        if (postTender.price) this.announceInfoModel.price = postTender.price;
        if (postTender.currency) this.announceInfoModel.currency = postTender.currency;
      }

      // this.quantity = this.postBoardQuantity;
      this.currency = this.postBoardCurrency;

      // this.supplyInfoModelList.forEach( item => {
      //   item.setData( { currency: this.postBoardCurrency, quantity: this.postBoardQuantity } );
      // });
    },
    async getSelectedPartnerDetail(postCid, tenderNum) {
      const path = `${this.$apiPath.BID}/${postCid}${this.$apiPath.EVALUATE}?num=${tenderNum}`;

      const result = await ApiService.shared.getData(path);

      if (!result.data) {
        this.alert(result.text);
        return;
      }

      this.setDataSelected(result.data);

      this.isAnnounceConnected = true;
    },
    setPManagerFromLocalStorage() {
      const pManager = this.managerInfoModel.pManager;

      const userData = LocalStorageManager.shared.getUserData();

      pManager.email = userData.email;
      pManager.tel = userData.tel;
      pManager.loginId = userData.username;
      pManager.name = userData.name;
    },
    // 구매요청서 정보 불러오기
    onClickPurchaseRequestesSearch() {
      this.isPopPurchaseRequests = true;
    },
    // 구매요청서 선택 팝업 닫기
    onClickClosePopPurchaseRequests() {
      this.isPopPurchaseRequests = false;
    },
    // 구매요청서 항목 삭제
    onClickRemovePurchaseReqeust() {
      this.purchaseWfOrder = {};
      this.estimateOrder = {};
      this.type = null;
      this.supplyInfoModelList = [];

      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();
      const model = new SupplyInfoItemModel();
      this.supplyInfoModelList.push(model);
      this.orderInfoModel = new OrderInfoModel();
      this.paymentInfoModel = new PaymentInfoModel();
    },
    // 구매신청서 항목 클릭시
    // onClickConnectRequestPost(item){
    //   this.requestCidSelected = item.requestCid;
    //   this.isPopRequestPost = true;
    // },
    // 구매신청서 상세 팝업 닫기
    onClickCloseRequestPost() {
      this.requestCidSelected = "";
      this.isPopRequestPost = false;
    },
    // 구매요청서 상세 연결
    onClickConnectPurchaseWfPost(item) {
      this.$windowOpen(`${this.$routerPath.PURCHASE_VIEW}/${item.requestCid}`);
    },
    // 계약서 상세 연결
    onClickConnectContract(item) {
      const { contractId } = item;
      const path = `${this.$routerPath.CONTRACT_VIEW}/${contractId}`;
      this.$windowOpen(path);
    },
    onAlert(text) {
      this.alert(text);
    },
    async onClickCreatePostCid() {
      const result = await ApiService.shared.getData(this.$apiPath.CREATE_ANNOUNCE_NUMBER);
      if (!result.data || !result.data.postCid) {
        this.alert(result.text);
        return;
      }

      this.postCid = result.data.postCid;
    },
    onClickSelect(rowData) {
      const query = this.$route.query;
      const requestCidInUrl = query.requestCid;

      if (requestCidInUrl != rowData.requestCid) {
        const params = {
          text: "발주서와 동일한 구매요청건의 입찰공고가 아닙니다.<br>그래도 선택하시겠습니까?",
          onClickY: () => this.onClickSelectY(rowData, true),
        };

        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      } else {
        this.onClickSelectY(rowData);
      }

      // this.isOrderAnnouncePopup = false;

      // this.postCid = rowData.postCid;

      // this.getSelectedPartnerDetail( rowData.postCid, rowData.tenderNum );
    },
    onClickSelectY(rowData, selectDiff) {
      this.isOrderAnnouncePopup = false;

      this.postCid = rowData.postCid;

      if (selectDiff) this.selectDiffPostCid = true;
      this.getSelectedPartnerDetail(rowData.postCid, rowData.tenderNum);
    },
    onClickDisconnect() {
      // 다 초기화.
      this.postBoard = {};
      this.postTender = {};

      this.postCid = "";
      this.announceInfoModel = new AnnounceInfoModel();

      this.managerInfoModel = new ManagerInfoModel();
      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();

      this.supplyPartnerManagerInfoModel = new SupplyPartnerManagerInfoModel();

      this.isAnnounceConnected = false;

      this.selectDiffPostCid = false;
      this.setPManagerFromLocalStorage();
    },
    onClickConfirmInPopPublish() {
      this.isPopPublish = false;

      this.saveData();
    },
    onClickSaveTemp() {
      const isTemp = true;
      if (!this.showPostCid && this.postCid == "") this.onClickCreatePostCid();

      this.saveData(isTemp);
    },
    async saveData(isTemp = false) {
      const alertMessage = this.getAlertMessage();

      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }

      let path = `${this.$apiPath.ORDER}/${this.orderCid}`;
      if (isTemp) path += "?temp=Y";

      const objToSend = this.getObjectForSave();

      //return;
      await ApiService.shared.postData(path, objToSend).then((result) => {
        const { code, data, text } = result;
        if (code !== "200") {
          this.alert(text);
          return;
        }

        const refreshPath = `${this.$routerPath.ORDER_VIEW}/${this.orderCid}`;

        this.$router.push({
          //path: this.$routerPath.ORDER_LIST
          path: refreshPath,
        });
      });
    },
    async orderRenewal() {
      //변경계약

      const obj = this.getObjectForSave();
      obj.contractStatus = "I";

      const result = await ApiService.shared
        .postData(`${this.$apiPath.ORDER}/renewal/${this.orderCid}`, obj)
        .then((response) => {
          const { code, text } = response;

          if (code !== "200") {
            this.alert(text);
            return;
          }

          const refreshPath = `${this.$routerPath.ORDER_VIEW}/${this.orderCid}`;

          // 저장 성공 시 그냥 목록으로.

          this.$router.push({
            path: refreshPath,
          });
        });
    },
    getAlertMessage() {
      let alertMessage = "";

      if (!this.templateId || !this.templateName) {
        return "발주서 템플릿을 선택해주세요";
      }

      alertMessage = this.orderInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.managerInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.supplyPartnerInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      alertMessage = this.supplyPartnerManagerInfoModel.getAlertMessage();
      if (alertMessage) return alertMessage;

      //if(this.vat == 'vatChoice') return '부가세를 확인해주세요.';

      // let item;

      // for (let i = 0; i < this.supplyInfoModelList.length; ++i) {
      //   item = this.supplyInfoModelList[i];

      //   alertMessage = item.getAlertMessage();

      //   if (alertMessage) return alertMessage;
      // }

      return null;
    },
    getObjectForSave() {
      let obj = {};

      obj.orderCid = this.orderCid;
      obj.postCid = this.postCid ? this.postCid : this.orderCid; //서버단에서 공고번호가 필수 값이므로 발주번호를 넣어 임시 세팅한다
      obj.projectCode = this.projectCode;
      obj.projectName = _.unescape(this.projectName);
      obj.templateId = this.templateId;
      obj.templateName = this.templateName;

      const orderInfo = this.orderInfoModel.getData();
      obj = Object.assign(obj, orderInfo);

      const { pManager, bManager, iManager } = this.managerInfoModel.getData();

      //Object.assign(obj, this.managerInfoModel.getData());

      obj.purchaseWfOrder = { requestCid: this.purchaseWfOrder.requestCid };

      obj.memo = this.memo;

      obj.postBoard = Object.assign({}, this.postBoard);

      const announceInfo = this.announceInfoModel.getData();
      obj.postBoard = Object.assign(obj.postBoard, announceInfo);

      obj.postBoard.purchasePersonList = [{ ...pManager }, { ...bManager }, { ...iManager }];

      obj.postTender = Object.assign({}, this.postTender);

      const supplyPartnerInfo = this.supplyPartnerInfoModel.getData();

      //obj.postTender.price = supplyPartnerInfo.price;
      //obj.postTender.price = this.orderInfoModel.price;
      obj.postTender.price = this.announceInfoModel.price;
      obj.postTender.proposalFileList = supplyPartnerInfo.proposalFileList;

      const { orderGoodsList, ...supplyInfoModelRest } = this.supplyInfoModel.getData();

      Object.assign(obj, { orderGoodsList: orderGoodsList }, { ...supplyInfoModelRest });
      obj.finalPrice = supplyInfoModelRest.orderGoodsAddInfo.totalContractAmount;
      obj.finalPriceKr = getMoneyToKorean(
        supplyInfoModelRest.orderGoodsAddInfo.totalContractAmount,
      );
      Object.assign(obj, { ...this.paymentMethodModel.getData() });

      //이하 기존
      obj.purchaseWfOrder = {
        requestCid: this.purchaseWfOrder.requestCid,
      };

      if (supplyPartnerInfo.estimateFile) {
        obj.postTender.estimateFile = supplyPartnerInfo.estimateFile;
      }

      // companyName 확인 필요.
      obj.postTender.tenderPartner = {
        companyName: supplyPartnerInfo.companyName,
        ceo: supplyPartnerInfo.ceo,
        corporateNum: supplyPartnerInfo.coporateNum,
        zipcode: supplyPartnerInfo.zipcode,
        address: supplyPartnerInfo.address,
        address2: supplyPartnerInfo.address2,
      };
      obj.postTender.personNum = supplyPartnerInfo.personNum;
      obj.postTender.userNum = supplyPartnerInfo.userNum;

      const supplyPartnerManagerInfo = this.supplyPartnerManagerInfoModel.getData();
      obj.postTender.tenderPersonList = [supplyPartnerManagerInfo];

      // // , 빼고 저장하자.
      // obj.orderGoodsLit = this.supplyInfoModelList.map((item) => {
      //   let itemData = item.getData();

      //   // Object.assign( itemData, { currency: this.currency, quantity: this.quantity } );
      //   Object.assign(itemData, { currency: this.currency });

      //   return itemData;
      // });

      const paymentInfo = this.paymentInfoModel.getData();

      obj = Object.assign(obj, paymentInfo);

      //obj.attachFileList = this.attachFileList;
      //obj.attachFileList = this.attachFileList[0];

      //const { truncationPrice, goodsNote } = this.$refs.supplyInfo.getSumData();

      // obj.truncationPrice = getMoneyToNumber(truncationPrice);
      // obj.goodsNote = goodsNote;

      // obj.cateNum = 6;
      if (obj.postBoard.cateNum) {
        obj.cateNum = obj.postBoard.cateNum;
      }

      obj.currency = this.currency;

      // 구매 신청 목록
      if (this.requestOrderList) {
        obj.requestOrderList = this.requestOrderList;
      }

      //}

      obj = Object.assign(obj, this.draftBudgetNumModel);

      if (this.vat) obj.vat = this.vat;

      //모든 첨부파일 추가
      obj.attachFile = this.attachFile;

      return obj;
    },
    onClickTemplateSelectPopup() {
      this.isPopTemplateSelect = true;
    },
    onClickClosePopTemplateSelect() {
      this.isPopTemplateSelect = false;
    },
    onClickSubmitPopTemplateSelect(selectedTemplate) {
      this.isPopTemplateSelect = false;
      this.templateName = selectedTemplate.templateName;
      this.templateId = selectedTemplate.templateId;
    },
    onClickCancelInPopPublish() {
      this.isPopPublish = false;
    },
    onClickPopPublish() {
      this.isPopPublish = true;
    },
    onClickConfirmInPopCancel() {
      this.isPopCancel = false;

      const nextPath =
        this.beforeWritingPage != "" ? this.beforeWritingPage : this.$routerPath.ORDER_LIST;

      this.$router.push({
        path: nextPath,
      });
    },
    onClickCancelInPopCancel() {
      this.isPopCancel = false;
    },
    onClickPopAnnounceList() {
      this.isOrderAnnouncePopup = true;
    },
    async onClickPreviewPop() {
      const obj = this.getObjectForSave();
      const url = `${this.$apiPath.ORDER}/template/preview/${this.orderCid}`;
      const result = await ApiService.shared.postData(url, obj);
      const { code, data, text } = result;

      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.previewId = data.previewId;
      //this.$windowOpen(`/templatePreview?previewId=${this.previewId}`, 'width=1290');
      this.isTemplatePopup = true;
    },
    onClickClosePreviewPopup() {
      this.isTemplatePopup = false;
      this.previewId = "";
    },
    onClickClose() {
      this.isPrintPopup = false;
      this.isPrintAble = false;
      this.isOrderAnnouncePopup = false;
    },
    onClickOrderPreviewClose() {
      this.isPrintPopup = false;
    },
    onClickPopCancel() {
      this.isPopCancel = true;
    },
    onClickPopLdap() {
      this.isPopLdap = true;
    },
    onClickClosePopLdap() {
      this.isPopLdap = false;
    },
    onClickSelectLdap(rowData) {
      this.isPopLdap = false;

      const { accountId, personName, email, emailId, tel, mobilePhone } = rowData;
      const obj = {
        requesterId: accountId,
        requesterName: personName,
        requesterTel: tel || mobilePhone,
        requesterEmail: email || emailId,
        requesterPersonNum: "",
      };
      this.managerInfoModel.setIManager(obj);
    },
    setPostCidWriteMode() {
      const { showPostCid } = this.$route.query;
      this.showPostCid = showPostCid == "N" ? false : true;

      if (!this.showPostCid) {
        this.onClickCreatePostCid();
      }
    },
    setPostCidModifyMode(data) {
      // 글 수정중일때 : postCid 가 존재하지 않고, 현업입찰 혹은 수의가 아닐때

      const { requestOrderList, postCid } = data;
      this.showPostCid =
        requestOrderList &&
        (requestOrderList[0].tenderYn == "B" || requestOrderList[0].tenderYn == "A")
          ? false
          : true;

      this.isAnnounceConnected = false;
      if (postCid && postCid != "") return;

      this.onClickCreatePostCid();
    },
    onClickPartnerInput() {
      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();
      this.supplyPartnerManagerInfoModel = new SupplyPartnerManagerInfoModel();

      this.isDirectPartnerInputMode = true;
    },
    onClickPopPartnerSelect() {
      this.isPopPartnerSelect = true;
    },
    onClickClosePopParnterSelect() {
      this.isPopPartnerSelect = false;
    },
    onClickAddPartner(rowData) {
      this.isPopPartnerSelect = false;

      // 제공 데이터 중 mobile을 기존 tel에 사용하기로.
      rowData.tel = rowData.mobile;

      const obj = {};
      obj.tenderPartner = rowData;

      this.supplyPartnerInfoModel.setData(obj);
      this.supplyPartnerManagerInfoModel.setData(rowData);

      this.isDirectPartnerInputMode = false;
    },
    onClickGoodsNamePopup(index) {
      this.isPopGoodsName = true;
      this.supplyInfoTargetIndex = index;
    },
    onClickCloseGoodsNamePopup() {
      this.isPopGoodsName = false;
      this.supplyInfoTargetIndex = null;
    },
    onClickCompleteGoodsNamePopup(selectedGoods) {
      this.isPopGoodsName = false;
      const SupplyInfoModelItem = this.supplyInfoModelList[this.supplyInfoTargetIndex];
      SupplyInfoModelItem.convertItemData(selectedGoods);
      this.supplyInfoTargetIndex = null;
    },
    onClickProjectCodePopup(index) {
      this.isPopProjectCode = true;
      this.supplyInfoTargetIndex = index;
    },
    onClickCloseProjectCodePopup() {
      this.isPopProjectCode = false;
      this.supplyInfoTargetIndex = null;
    },
    onClickCompleteProjectCodePopup(selectedProjectCode) {
      this.isPopProjectCode = false;
      this.supplyInfoModelList[this.supplyInfoTargetIndex].projectCode = selectedProjectCode;
      this.supplyInfoTargetIndex = null;
    },
    // 견적서 정보 불러오기
    onClickEstimateRequestesSearch() {
      this.isPopEstimateRequests = true;
    },
    // 견적서 선택 팝업 닫기
    onClickClosePopEstimateRequests() {
      this.isPopEstimateRequests = false;
    },

    onClickConnectEstimateWfPost(item) {
      this.$windowOpen(`${this.$routerPath.ESTIMATE_VIEW}/${item.estimateNum}`);
    },
    // 견적서 항목 삭제
    onClickRemoveEstimateReqeust() {
      this.estimateOrder = {};
      this.type = "purchase";
      this.supplyPartnerInfoModel = new SupplyPartnerInfoModel();
      this.supplyPartnerManagerInfoModel = new SupplyPartnerManagerInfoModel();
      // this.paymentInfoModel = new PaymentInfoModel();
      // this.requestContractList = [];
      // this.contractInfoModel.finalPrice = '';

      // this.paymentMethodModel.downPaymentRate = '0';
      // this.paymentMethodModel.middlePaymentRate = '0';
      // this.paymentMethodModel.balance = '0';
    },

    async onClickPopEstimatePrint(item) {
      item.quotationList.forEach((company) => {
        if (company.status == "S") {
          this.selectedCompany = company;
        }
      });

      const path = `${this.$apiPath.QUOTATION}/${this.selectedCompany.quotationNum}`;
      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.estimatePrint = data;
      this.isPopEstimatePrint = true;
    },

    onClickClosePopEstimatePrint() {
      this.isPopEstimatePrint = false;
    },

    setEstimateContract(item) {
      const { estimateNum } = item;
      this.estimateNum = estimateNum;

      this.type = "estimate";
      this.getEstimateRequest();
    },

    async setPurchaseContract(item) {
      const { requestCid, type } = item;
      this.requestCid = requestCid;
      this.type = "purchase";

      this.getPurchaseRequest();
      return;
    },
    // 현업담당자 자동입력
    async setBManager(accountId) {
      if (!accountId) return;
      const path = `${this.$apiPath.MANAGER_LDAP}?name=${accountId}`;
      const result = await ApiService.shared.getData(path);
      if (result.data && result.data.length > 0) {
        this.onClickSelectLdap(result.data[0]);
      }
    },
    // 발주일 자동입력
    setOrderDateNow() {
      // 발주일
      // 현재시간 dateToStringWithTimeHasDiv(new Date);

      this.orderInfoModel.orderDate = new Date(this.moment(new Date()).set({ hour: 0, minute: 0 }));
    },
    // 발주서 자동입력
    async setDefaultTemplate(templateGubun, templateId = null) {
      const path = `${this.$apiPath.TEMPLATE_LIST}?&page=1&size=1&templateGubun=${templateGubun}`;
      const result = await ApiService.shared.getData(path);
      if (result.data && result.data[0]) {
        if (templateId) {
          // 있으면 filter
          const filter = result.data.filter((item) => item.templateId === templateId);
          if (filter.length > 0) {
            this.onClickSubmitPopTemplateSelect(filter[0]);
          }
        } else {
          // 없으면 첫번쨰
          this.onClickSubmitPopTemplateSelect(result.data[0]);
        }
      }
    },
    // 납품정보 기본값 입력
    setPaymentInfoDefault() {
      this.paymentInfoModel.payMethod = "현금 100%";
      this.paymentInfoModel.payCondition = "세금계산서 발행일로부터 20일 이내 대금 지급";
      this.paymentInfoModel.goodsPlace = "당사 지정장소";
      this.paymentInfoModel.deposit = "해당없음";
    },
    onClickOrderRemove() {
      const funcRemove = async () => {
        const path = `${this.$apiPath.ORDER}/${this.orderCid}`;
        const result = await ApiService.shared.deleteData(path);
        const { code, message } = result;
        if (code !== "200") {
          this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, message);

          return;
        }
        this.$router.push({
          path: this.$routerPath.ORDER_LIST,
        });
      };

      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: "발주서를 삭제하시겠습니까?",
        onClickY: funcRemove,
      });
    },

    onClickRenewalConfirm() {
      const alertMessage = this.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isOrderReasonPopup = true;
    },
    onClickReasonClose() {
      //변경계약 사유 팝업닫기
      this.isOrderReasonPopup = false;
      this.changeReason = "";
    },
    onClickReasonSubmit() {
      //변경계약 사유 전송
      if (this.type === "renewal") {
        //API 전송
        if (!this.changeReason) {
          this.alert("변경발주 사유를 입력해주세요");
          return;
        }
        const params = {
          text: `변경발주를 요청하시겠습니까?`,
          onClickY: () => {
            this.OrderRenewal();
          },
        };
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
      }
    },
  },
};
</script>
