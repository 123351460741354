<template>
  <TableViewWithTitle title="담당자 정보">
    <template v-slot:colgroup>
      <col style="width: 120px" />
      <col style="width: 294px" />
      <col style="width: 120px" />
      <col style="width: 294px" />
      <col style="width: 120px" />
      <col style="width: 294px" />
    </template>
    <template v-slot:header>
      <tr>
        <th colspan="2">구매 담당자</th>
        <th colspan="2">구매 요청자</th>
        <th colspan="2">검수 담당자</th>
      </tr>
    </template>
    <template v-slot:body>
      <tr>
        <th>구매 담당자</th>
        <td>
          {{ `${managerInfoModel.pManager.name} (${managerInfoModel.pManager.loginId})` }}
        </td>
        <th>구매 요청자</th>
        <td>
          {{ `${managerInfoModel.bManager.name} (${managerInfoModel.bManager.loginId})` }}
        </td>
        <th>검수 담당자<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <div class="group_form">
            <button type="button" class="btn_fourthly btn_small" @click="onClickPopLdap">
              조직도 검색
            </button>
            <Input
              style="width: 170px"
              placeholder="조직도 아이디를 입력하세요"
              :isDisabled="true"
              :value.sync="computedImanager"
            />
          </div>
        </td>
      </tr>
      <tr>
        <th>전화번호 (내선)</th>
        <td>
          {{ `${managerInfoModel.pManager.tel} ` | nullToDash }}
        </td>
        <th>전화번호 (내선)</th>
        <td>
          {{ `${managerInfoModel.bManager.tel}` | nullToDash }}
        </td>
        <th>전화번호 (내선)</th>
        <td>
          {{ `${managerInfoModel.iManager.tel}` | nullToDash }}
        </td>
      </tr>
      <tr>
        <th>이메일</th>
        <td>
          {{ `${managerInfoModel.pManager.email}` | nullToDash }}
        </td>
        <th>이메일</th>
        <td>
          {{ `${managerInfoModel.bManager.email}` | nullToDash }}
        </td>
        <th>이메일</th>
        <td>
          {{ `${managerInfoModel.iManager.email}` | nullToDash }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";

export default {
  name: "ManagerInfo",
  components: {
    TableViewWithTitle,
    Input,
  },
  props: {
    managerInfoModel: Object,
  },
  computed: {
    bManager() {
      return this.managerInfoModel.bManager;
    },
    computedImanager() {
      const { name, loginId } = this.managerInfoModel.iManager;

      if (!name && !loginId) {
        return "";
      }

      return `${name} (${loginId})`;
    },
  },
  methods: {
    onClickPopLdap() {
      this.$emit("onClickPopLdap");
    },
  },
};
</script>
