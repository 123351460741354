import {
  dateToString,
  stringToDateWithTime,
  dateToStringWithTime,
  stringToDate,
} from "@/utils/dateUtils";

class OrderInfoModel {
  constructor() {
    // this.orderCid = '',

    this.title = ""; // 발주서명.
    // this.goodsPlace = ''; // 납품장소, 입고처?
    this.dates = [];

    this.goodsDeadline = null; // 납품 기한
    this.orderDate = null; // 발주 날짜.

    this.contractId = "";
    this.contractName = "";
  }

  setData(objRaw) {
    const {
      title,
      dates,
      goodsPlace,
      goodsDeadline,
      orderDate,
      contractId,
      contractName,
      orderStartDate,
      orderEndDate,
    } = objRaw;

    if (title) this.title = title;
    // if( goodsPlace )  this.goodsPlace = goodsPlace;

    if ((orderStartDate, orderEndDate)) {
      this.dates = [stringToDate(orderStartDate), stringToDate(orderEndDate)];
    }
    if (goodsDeadline) this.goodsDeadline = stringToDateWithTime(goodsDeadline);
    if (dates) this.dates = [];
    if (orderDate) this.orderDate = stringToDateWithTime(orderDate);
    if (contractId) this.contractId = contractId;
    if (contractName) this.contractName = contractName;
  }

  getData() {
    const obj = {
      title: this.title,
      contractId: this.contractId,
      contractName: this.contractName,
      // goodsPlace: this.goodsPlace,
    };
    if (this.dates && this.dates.length === 2) {
      obj.orderStartDate = dateToString(this.dates[0]);
      obj.orderEndDate = dateToString(this.dates[1]);
    }

    if (this.goodsDeadline) {
      obj.goodsDeadline = dateToStringWithTime(this.goodsDeadline);
    }
    if (this.orderDate) {
      obj.orderDate = dateToStringWithTime(this.orderDate);
    }

    return obj;
  }

  getAlertMessage() {
    // const defaultAlertMessage = '발주 정보를 확인해주세요.'

    if (!this.dates && this.dates.length !== 2) return "계약기간을 확인해주세요";
    if (!this.orderDate) return "계약 체결일을 확인해주세요.";
    if (!this.goodsDeadline) return "납품기한을 확인해주세요.";

    return null;
  }
}

export default OrderInfoModel;
