<template>
  <Fold title="대금조건">
    <TableView>
      <template v-slot:body>
        <tr>
          <th>대금 청구 조건<span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <div class="group_form">
              <SelectBox
                style="width: 120px"
                :dataList="paymentTypeList"
                :value.sync="paymentMethodModel.paymentType"
                @onChange="onChangeBillingTerms"
              />
              <Input :value.sync="paymentMethodModel.paymentDescription" />
            </div>
          </td>
          <th>대금 지급 조건<span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <Input :value.sync="paymentMethodModel.executionCondition" />
          </td>
        </tr>
      </template>
    </TableView>

    <TableViewWithTitle v-if="paymentMethodModel.paymentType === 'D'" style="margin-top: 10px">
      <template v-slot:headRight>
        <div class="group_form">
          <span class="txt_view_r">중도금을</span>
          <InputNumber class="w48" :value.sync="setInstallmentRate" />
          <span class="txt_view_r">비율(%)로</span>
          <InputNumber class="w48" :value.sync="setInstallmentCount" />
          <span class="txt_view_r">건 만큼</span>
          <button
            class="btn_secondary btn_medium"
            :disabled="isAutosetting"
            @click="onClickInstallmentRate"
          >
            중도금 자동 세팅
          </button>
          <span class="bar_view"></span>
          <button class="btn_secondary btn_medium" @click="addInstallmentItem">추가</button>
        </div>
      </template>
      <template v-slot:colgroup>
        <!-- No -->
        <col style="width: 40px" />
        <!-- 구분 -->
        <col style="width: 152px" />
        <!-- 지급비율 -->
        <col style="width: 428px" />
        <!-- 지급금액 -->
        <col />
        <!-- 버튼 -->
        <col style="width: 86px" />
      </template>
      <template v-slot:header>
        <th scope="col">No.</th>
        <th scope="col">구분</th>
        <th scope="col">지급비율(%)</th>
        <th scope="col">지급금액</th>
        <th scope="col"></th>
      </template>
      <template v-slot:body>
        <template v-for="(item, index) in paymentMethodModel.orderPartialPayments">
          <PaymentMethodRateLine
            :key="index"
            :item="item"
            :index="index"
            :lastIndex="paymentMethodModel.orderPartialPayments.length - 1"
            :contractTotalPrice="computedContractTotalPrice"
            @removeInstallmentItem="removeInstallmentItem"
            @amountChange="amountChangeInstallmentItem"
          />
        </template>
      </template>
    </TableViewWithTitle>

    <TableViewWithTitle>
      <template v-slot:colgroup>
        <!-- 비율문구 -->
        <col style="width: 124px" />
        <!-- 비율 -->
        <col style="width: 80px" />
        <!-- 보증방법 -->
        <col style="width: 80px" />
        <!-- 보증방법 -->
        <col style="width: 160px" />
        <!-- 증권번호문구 -->
        <col style="width: 150px" />
        <!-- 증권번호 -->
        <col style="width: 248px" />
        <!-- 첨부파일문구 -->
        <col style="width: 150px" />
        <!-- 첨부파일 -->
        <col style="width: 248px" />
      </template>
      <template v-slot:body>
        <tr>
          <th>선급금보증비율(%)<span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <div class="group_form">
              <InputRate
                :isValid="false"                
                :inputClass="'align_right'"
                :maxLength="5"
                :maxValue="100"
                :value.sync="paymentMethodModel.advanceRate"
              />
            </div>
          </td>
          <th>보증방법 <span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <SelectBox
              :placeholder="'선택'"
              :dataList="defactDataList"
              :value.sync="paymentMethodModel.advancePaymentGuaranteeType"
            />
          </td>
          <th>선급금보증증권 번호</th>
          <td>
            {{ paymentMethodModel.advancePaymentSurety | nullToDash }}
          </td>
          <th>선급금보증증권 첨부파일</th>
          <td>
            <FileView :dataList.sync="attachFile.advancePaymentSuretyAttachFile" />
          </td>
        </tr>
        <tr>
          <th>계약보증비율(%)<span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <div class="group_form">
              <InputRate
                :isValid="false"
                :inputClass="'align_right'"
                :maxLength="5"
                :maxValue="100"
                :value.sync="paymentMethodModel.contractRate"
              />
            </div>
          </td>
          <th>보증방법 <span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <SelectBox
              :placeholder="'선택'"
              :dataList="defactDataList"
              :value.sync="paymentMethodModel.contractGuaranteeType"
            />
          </td>
          <th>계약이행증권 번호</th>
          <td>{{ paymentMethodModel.contractSurety | nullToDash }}</td>
          <th>계약이행증권 첨부파일</th>
          <td><FileView :dataList.sync="attachFile.contractSuretyAttachFile" /></td>
        </tr>
        <tr>
          <th>하자보증비율(%)<span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <div class="group_form">
              <InputRate
                :isValid="false"
                :inputClass="'align_right'"
                :maxLength="5"
                :maxValue="100"
                :value.sync="paymentMethodModel.defectRate"
              />
            </div>
          </td>
          <th>보증방법 <span class="ico_purchase ico_star">필수항목</span></th>
          <td>
            <SelectBox
              :placeholder="'선택'"
              :dataList="defactDataList"
              :value.sync="paymentMethodModel.defectGuaranteeType"
            />
          </td>
          <th>하자이행증권 번호</th>
          <td>{{ paymentMethodModel.defectSurety | nullToDash }}</td>
          <th>하자이행증권 첨부파일</th>
          <td><FileView :dataList.sync="attachFile.defectSuretyAttachFile" /></td>
        </tr>
        <tr>
          <th>지체상금</th>
          <td colspan="3">
            <div class="group_form">
              <InputRate
                style="width: 120px"
                :inputClass="'align_right'"
                :maxLength="6"
                :min="0"
                :max="1000"
                :value.sync="paymentMethodModel.penaltyRate"
              />
              <div class="txt_view_r">/1000</div>
            </div>
          </td>
          <th>하자보증기간</th>
          <td>
            <div class="group_form">
              <InputNumber
                style="width: 120px"
                :inputClass="'align_right'"
                :maxLength="2"
                :value.sync="paymentMethodModel.warranty"
              />
              <div class="txt_view_r">개월</div>
            </div>
          </td>
          <td></td>
          <td></td>
        </tr>
      </template>
    </TableViewWithTitle>
  </Fold>
</template>

<script>
import Fold from "@/components/common/fold/Fold";
import TableView from "@/components/shared/tableView/TableView";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import DateRange from "@/components/common/calendar/DateRange";
import DatePicker from "@/components/common/calendar/DatePicker";
import Input from "@/components/common/input/Input";
import InputNumber from "@/components/common/input/InputNumber";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import InputRate from "@/components/common/input/InputRate";

import SelectBox from "@/components/common/selectBox/SelectBox";
import SelectBoxCurrencyIncludeData from "@/components/common/selectBox/SelectBoxCurrencyIncludeData";
import FileWrite from "@/components/common/file/FileWrite";
import FileView from "@//components/common/file/FileView";

import PaymentMethodRateLine from "./PaymentMethodRateLine";

import PaymentMethodModel from "./PaymentMethodModel";
import PaymentMethodRateLineModel from "./PaymentMethodRateLineModel";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";
// import OrderInfoModel from './OrderInfoModel'

export default {
  name: "PaymentMethod",
  components: {
    Fold,
    TableView,
    TableViewWithTitle,
    DateRange,
    DatePicker,
    Input,
    InputNumber,
    InputRate,
    InputAutoMoney,
    SelectBox,
    SelectBoxCurrencyIncludeData,
    FileWrite,
    FileView,

    PaymentMethodRateLine,
  },
  props: {
    paymentMethodModel: Object,

    supplyInfoModel: Object,
    attachFile: Object,
  },
  data() {
    return {
      paymentTypeList: [
        { id: "D", code: "D", desc: "분할지급" },
        { id: "R", code: "R", desc: "기성지급" },
      ],
      defactDataList: [
        { id: "G", code: "G", desc: "보증보험증권" },
        { id: "M", code: "M", desc: "각서" },
        { id: "E", code: "E", desc: "보증증권면제" },
      ],
      defalultInstallmentItem: {
        billingType: "S",
        billingName: "중도금",
        rate: "0",
        amount: "0",
      },
      contractTotalPrice: 0,
      setInstallmentRate: "",
      setInstallmentCount: "",
    
    };
  },
  computed: {
    isAutosetting() {
      return !(this.setInstallmentRate !== "" && this.setInstallmentCount !== "");
    },
    computedContractTotalPrice() {
      return Number(this.contractTotalPrice);
    },
  },
  watch: {
    "supplyInfoModel.totalContractAmount": function (newVal, oldVal) {
      this.contractTotalPrice = newVal;
      if (this.paymentMethodModel.paymentType === "R") return;
      this.paymentMethodModel.orderPartialPayments[
        this.paymentMethodModel.orderPartialPayments.length - 1
      ].amount = String(newVal);
      this.amountChangeInstallmentItem();
    },
    "paymentMethodModel.advancePaymentGuaranteeType": function (newVal, oldVal) {
    },
    "paymentMethodModel.contractGuaranteeType": function (newVal, oldVal) {
    },
    "paymentMethodModel.defectGuaranteeType": function (newVal, oldVal) {
    },
    "paymentMethodModel.advanceRate": function (newVal, oldVal) {
      // this.isValidSuretyRateCheck();
    },
    "paymentMethodModel.contractRate": function (newVal, oldVal) {
      // this.isValidSuretyRateCheck();
    },
    "paymentMethodModel.defectRate": function (newVal, oldVal) {
      // this.isValidSuretyRateCheck();
    },
  },
  created() {
    if (this.paymentMethodModel.orderPartialPayments.length === 0) {
      this.initInstallmentItem();
    }
  },
  methods: {
    onChangeBillingTerms(value) {
      const filterItem = this.paymentTypeList.find((item) => {
        return item.id === value;
      });
      this.paymentMethodModel.paymentDescription = filterItem.desc;
      if (filterItem.id === "D") {
        this.initInstallmentItem();
      }
    },
    initInstallmentItem() {
      const initDataList = [
        { billingType: "D", billingName: "선급금", rate: "0", amount: "0" },
        { billingType: "S", billingName: "중도금", rate: "0", amount: "0" },
        { billingType: "B", billingName: "잔금", rate: "100", amount: "0" },
      ];

      const setDataList = initDataList.reduce((acc, item, index) => {
        const itemModel = new PaymentMethodRateLineModel();
        itemModel.setData(item);
        acc.push(itemModel);
        return acc;
      }, []);

      this.paymentMethodModel.orderPartialPayments = setDataList;
    },
    addInstallmentItem() {
      //단건 추가
      const itemModel = new PaymentMethodRateLineModel();
      itemModel.setData(this.defalultInstallmentItem);
      const chunkCount =
        this.paymentMethodModel.orderPartialPayments.length === 1
          ? 1
          : this.paymentMethodModel.orderPartialPayments.length - 1;
      const chunkArray = _.chunk(this.paymentMethodModel.orderPartialPayments, chunkCount);
      const [items, balanceItem] = chunkArray;
      let ret = [];
      if (this.paymentMethodModel.orderPartialPayments.length === 1) {
        ret = [itemModel, ...items];
      } else {
        ret = [...items, itemModel, ...balanceItem];
      }
      this.paymentMethodModel.orderPartialPayments = [...ret];
    },
    onClickInstallmentRate() {
      //자동 세팅
      const { setInstallmentRate, setInstallmentCount } = this;
      const calcRate = Number(setInstallmentRate) * Number(setInstallmentCount);
      if (this.contractTotalPrice === 0) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: `총 계약금액을 확인해주세요`,
        });
        return;
      }
      if (calcRate > 100) {
        this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
          text: `총 비율이 100% 를 초과할 수 없습니다.`,
        });
        return;
      }
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, {
        text: `세팅한 데이터가 초기화 됩니다.<br />계속진행하시겠습니까?`,
        onClickY: () => {
          this.addInstallmentRate();
        },
      });
    },
    addInstallmentRate() {
      const { setInstallmentRate, setInstallmentCount } = this;
      let installmentList = [];
      const firstObj = {
        billingType: "D",
        billingName: "선급금",
        rate: "0",
        amount: "0",
      };
      const lastObj = {
        billingType: "B",
        billingName: "잔금",
        rate: "100",
        amount: "0",
      };
      const setObj = {
        billingType: "S",
        billingName: "중도금",
        rate: String(setInstallmentRate),
        amount: "0",
      };
      for (let i = 0; i < setInstallmentCount; i++) {
        const itemModel = new PaymentMethodRateLineModel();
        itemModel.setData(setObj);
        installmentList.push(itemModel);
      }
      const firstItemModel = new PaymentMethodRateLineModel();
      firstItemModel.setData(firstObj);
      const lastItemModel = new PaymentMethodRateLineModel();
      lastItemModel.setData(lastObj);

      const ret = [firstItemModel, ...installmentList, lastItemModel];
      this.paymentMethodModel.orderPartialPayments = ret;
    },

    removeInstallmentItem(removeIndex) {
      const removeArray = this.paymentMethodModel.orderPartialPayments.filter((item, index) => {
        if (removeIndex !== index) {
          return item;
        }
      });
      this.paymentMethodModel.orderPartialPayments = [...removeArray];
      this.amountChangeInstallmentItem();
    },
    amountChangeInstallmentItem() {
      //마지막꺼
      const { orderPartialPayments } = this.paymentMethodModel;
      const balanceItem = orderPartialPayments[orderPartialPayments.length - 1];
      const items = orderPartialPayments.filter((item, index) => {
        // 나머지를 뽑는다
        if (index !== orderPartialPayments.length - 1) {
          return item;
        }
      });
      const calcBalanceItem = items.reduce(
        (acc, item, index) => {
          acc.rate -= Number(item.rate);
          acc.amount -= getMoneyToNumber(item.amount);
          if (acc.rate < 0) {
            this.$toast(`지급 비율의 합이 100% 를 넘길수 없습니다.`);
            acc.rate = Number(String(acc.amount).slice(0, -1));
          }

          return acc;
        },
        { rate: 100, amount: this.contractTotalPrice },
      );

      balanceItem.setData({
        rate: String(_.round(calcBalanceItem.rate, 2)),
        amount: String(_.round(Number(calcBalanceItem.amount), 2)),
      });
    },

    isValidSuretyRateCheck() {
      const { advanceRate, contractRate, defectRate } = this.paymentMethodModel;
      let rate = 0;

      rate += Number(advanceRate);
      rate += Number(contractRate);
      rate += Number(defectRate);
      if (rate > 100) {
        this.$toast(`보증 비율의 합이 100% 를 넘길수 없습니다.`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.group_form {
  display: flex;
}
</style>
