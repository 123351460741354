import PhoneModel from "@/models/PhoneModel";
import EmailModel from "@/models/EmailModel";

class SupplyPartnerInfoModel {
  constructor() {
    this.companyName = ""; // 업체명
    this.ceo = ""; // 대표이사명
    //this.price = '';  // 금액
    this.estimateFileList = [];
    this.proposalFileList = []; // 서버에서는 오브젝트로 받음.

    this.corporateNum = ""; // 사업자 등록번호
    this.zipcode = ""; // 우편번호
    this.address = ""; // 주소
    this.address2 = ""; // 주소
    this.personNum = "";
    this.userNum = "";
    this.email = new EmailModel();
    this.tel = new PhoneModel("010");
    this.mobile = new PhoneModel("010");
  }

  // setData(postTender) {
  //   const { estimateFile, proposalFileList, tenderPartner } = postTender;

  //   //if( price ) this.price = price;
  //   if (proposalFileList) this.proposalFileList = proposalFileList;
  //   if (estimateFile) {
  //     this.estimateFileList = [estimateFile];
  //   }

  //   if (tenderPartner) {
  //     if (tenderPartner.companyName) this.companyName = tenderPartner.companyName;

  //     if (tenderPartner.corporateNum && tenderPartner.corporateNum !== '0')
  //       this.corporateNum = tenderPartner.corporateNum;
  //     if (tenderPartner.ceo) this.ceo = tenderPartner.ceo;
  //     if (tenderPartner.zipcode) this.zipcode = tenderPartner.zipcode;
  //     if (tenderPartner.address) this.address = tenderPartner.address;
  //     if (tenderPartner.address2) this.address2 = tenderPartner.address2;
  //     if (tenderPartner.personNum) this.personNum = tenderPartner.personNum;
  //     if (tenderPartner.userNum) this.userNum = tenderPartner.userNum;
  //   }
  // }

  setData(obj) {
    this.objRaw = obj;

    const {
      companyName,
      ceo,
      corporateNum,
      zipcode,
      address,
      address2,
      name,
      tel,
      mobile,
      email,
      personNum,
      userNum,
      estimateFile,
      proposalFileList,
      tenderPartner,
    } = obj;

    this.companyName = companyName || tenderPartner.companyName || "";
    this.ceo = ceo || tenderPartner.ceo || "";
    this.corporateNum = corporateNum || tenderPartner.corporateNum || "";
    this.name = name || "";

    this.zipcode = zipcode || tenderPartner.zipcode || "";
    this.address = address || tenderPartner.address || "";
    this.address2 = address2 || tenderPartner.address2 || "";
    this.personNum = personNum || tenderPartner.personNum || "";
    this.userNum = userNum || tenderPartner.userNum || "";

    if (proposalFileList) this.proposalFileList = proposalFileList;

    if (estimateFile) {
      this.estimateFileList = [estimateFile];
    }

    if (mobile) {
      this.tel.setDataByString(mobile);
    } else {
      this.tel = new PhoneModel("010");
    }

    if (email) {
      this.email.setDataByString(email);
    } else {
      this.email = new EmailModel();
    }
  }

  getData() {
    const obj = {
      companyName: this.companyName,
      ceo: this.ceo,
      //price: this.price,
      proposalFileList: this.proposalFileList,
      coporateNum: this.corporateNum,
      zipcode: this.zipcode,
      address: this.address,
      address2: this.address2,
      personNum: this.personNum,
      userNum: this.userNum,
    };

    if (this.estimateFileList && this.estimateFileList.length > 0) {
      obj.estimateFile = this.estimateFileList[0];
    }

    return obj;
  }

  getAlertMessage() {
    // const defaultAlertMessage = '파트너사 정보를 확인해주세요.'

    if (!this.companyName) return "파트너사 업체명을 확인해주세요.";
    if (!this.ceo) return "파트너사 대표이사명을 확인해주세요.";

    return null;
  }
}

export default SupplyPartnerInfoModel;
