<template>
  <div class="area_view">
    <TableHead title="납품 정보" />
    <TableView>
      <template v-slot:colgroup>
        <col style="width: 192px" />
        <col />
      </template>
      <template v-slot:body>
        <tr>
          <th>대금 지급 방법</th>
          <td>
            <Input :value.sync="model.payMethod" />
          </td>
        </tr>
        <tr>
          <th>대금 지급 조건</th>
          <td>
            <Input :value.sync="model.payCondition" />
          </td>
        </tr>
        <tr>
          <th>납품 장소</th>
          <td>
            <Input :value.sync="model.goodsPlace" />
          </td>
        </tr>
        <tr>
          <th>계약&#47;하자 보증금</th>
          <td>
            <Input :value.sync="model.deposit" />
          </td>
        </tr>
        <tr>
          <th>기타</th>
          <td class="h96">
            <Textarea :value.sync="model.otherTerms" />
          </td>
        </tr>
      </template>
    </TableView>
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import TableView from "@/components/shared/tableView/TableView";
import Input from "@/components/common/input/Input";
import Textarea from "@/components/common/textarea/Textarea";

import PaymentInfoModel from "./PaymentInfoModel";

export default {
  name: "PaymentInfo",
  components: {
    TableHead,
    TableView,
    Input,
    Textarea,
  },
  props: {
    model: PaymentInfoModel,
  },
};
</script>
