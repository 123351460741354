<template>
  <div class="area_view">
    <TableHead :title="`${assetTypeName ? assetTypeName : ''} 품목 상세`">
      <!-- <template v-slot:headRight>
        <div class="group_form">
          <SelectBox class="w130" :dataList="vatDataList" :value.sync="vatSync" />
        </div>
      </template> -->
    </TableHead>
    <Board :disableHover="true" :dataList="supplyInfoModel.orderGoodsList" :showPagination="false">
      <template v-slot:colgroup>
        <!-- No -->
        <col style="width: 40px" />
        <!-- 품명 -->
        <col />
        <!-- 품목코드 -->
        <col style="width: 110px" />
        <!-- 자산코드 -->
        <col style="width: 110px" />
        <!-- 단위 -->
        <col style="width: 60px" />
        <!-- 수량 -->
        <col style="width: 60px" />
        <!-- 예산금액 -->
        <col style="width: 120px" />
        <!-- 소비자가 -->
        <col style="width: 120px" />
        <!-- 계약금액 -->
        <col style="width: 120px" />
        <!-- 할인율(%) -->
        <col style="width: 80px" />
        <!-- 부가세 -->
        <col style="width: 120px" />
        <!-- 절감액 -->
        <col style="width: 120px" />
        <!-- 절감율(%) -->
        <col style="width: 80px" />
        <!-- 비고 -->
        <col style="width: 120px" />
      </template>
      <template v-slot:tr>
        <th scope="col">No.</th>
        <th scope="col">품명</th>
        <th scope="col">품목코드</th>
        <th scope="col">자산코드</th>
        <th scope="col">단위</th>
        <th scope="col">수량</th>
        <th scope="col">예산금액</th>
        <th scope="col">소비자가<span class="ico_purchase ico_star">필수항목</span></th>
        <th scope="col">계약금액<span class="ico_purchase ico_star">필수항목</span></th>
        <th scope="col">할인율(%)</th>
        <th scope="col">부가세</th>
        <th scope="col">절감액</th>
        <th scope="col">절감율(%)</th>
        <th scope="col">품목상세</th>
        <!-- <th>
          <button type="button" class="btn_secondary btn_small" @click="onClickAdd">추가</button>
        </th> -->
      </template>
      <template v-slot:rows>
        <SupplyInfoLine
          v-for="(item, index) in supplyInfoModel.orderGoodsList"
          :key="index"
          :item="item"
          :index="index"
          @onClickGoodsNamePopup="(index) => $emit('onClickGoodsNamePopup', index)"
          @changePrice="changePrice"
        />
        <!-- @onClickRemove="onClickRemove" -->
      </template>
      <template v-slot:emptyList>
        <tr>
          <td colspan="13">구매요청서를 연결해주세요.</td>
        </tr>
      </template>
    </Board>
    <SupplyFinalPrice :attachFile="attachFile" :supplyInfoModel="supplyInfoModel" />
  </div>
</template>

<script>
import TableHead from "@/components/shared/TableHead";
import Board from "@/components/common/board/Board";
import SupplyInfoLine from "./SupplyInfoLine";

import SelectBoxCurrencyNoInputCount from "@/components/common/selectBox/SelectBoxCurrencyNoInputCount";
import SelectBox from "@/components/common/selectBox/SelectBox";

import SupplyFinalPrice from "./SupplyFinalPrice";

import { addCommaForMoney, getMoneyToNumber } from "@/utils/stringUtils";

//import SupplyFinalPriceModel from './SupplyFinalPriceModel';

export default {
  name: "SupplyInfo",
  components: {
    TableHead,
    Board,
    SupplyInfoLine,
    SelectBoxCurrencyNoInputCount,
    SelectBox,
    SupplyFinalPrice,
  },
  props: {
    assetTypeName: String,
    supplyInfoModel: Object,
    attachFile: Object,
  },
  data() {
    return {
      //supplyFinalPriceModel: new SupplyFinalPriceModel(),
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    changePrice(item) {
      this.setFinalData();
    },
    setFinalData() {
      const { orderGoodsList } = this.supplyInfoModel;

      const totalSavingAmount = orderGoodsList.reduce((acc, item, index) => {
        const { savingAmount } = item.getData();
        let ret = acc;
        ret = acc + savingAmount;
        return ret;
      }, 0);
      const totalContractAmount = orderGoodsList.reduce((acc, item, index) => {
        const { contractPrice } = item.getData();
        let ret = acc;
        ret = acc + contractPrice;
        return ret;
      }, 0);
      const totalVat = orderGoodsList.reduce((acc, item, index) => {
        const { vat } = item.getData();
        let ret = acc;
        ret = acc + vat;
        return ret;
      }, 0);
      const finalData = {
        totalSavingAmount: totalSavingAmount,
        totalContractAmount: totalContractAmount,
        totalVat: totalVat,
      };

      this.supplyInfoModel.setData(finalData);
    },

    onClickRemove(itemToRemove) {
      const arr = this.supplyInfoModel.orderGoodsList.filter((item) => item !== itemToRemove);

      this.$emit("update:dataList", arr);
    },
    onClickAdd() {
      if (this.supplyInfoModel.orderGoodsList.length >= 10) {
        this.alert("총 10개까지 가능합니다.");
        return;
      }
    },
    alert(text) {
      this.$emit("alert", text);
    },
    initItem() {
      this.supplyInfoModel.addItem();
    },
  },
};
</script>
