import { stringToDateWithTime, dateToStringWithTime, dateToString } from "@/utils/dateUtils";
import { getMoneyToNumber, getMoneyToKorean } from "@/utils/stringUtils";

import PaymentMethodRateLineModel from "./PaymentMethodRateLineModel.js";

export default class PaymentMethodModel {
  constructor() {
    this.objRaw = {};
    this.orderCid = ""; //발주번호
    this.paymentType = "D";
    this.paymentDescription = "분할지급"; //대금 청구조건
    this.executionCondition = "계약일반조건에 따름"; //대금 지급조건
    this.orderPartialPayments = [];

    this.advanceRate = "0"; //선급금보증비율
    this.advancePaymentGuaranteeType = ""; //선급금보증방법
    this.advancePaymentSurety = ""; //선급금이행증서

    this.contractRate = "0"; //계약보증비율
    this.contractGuaranteeType = ""; //계약보증비율
    this.contractSurety = ""; //계약이행증서

    this.defectRate = "0"; //하자보증비율
    this.defectGuaranteeType = ""; //하자보증방법
    this.defectSurety = ""; //하자이행증서

    this.penaltyRate = "0"; //지체상금
    this.warranty = "0"; //하자보증기간

    this.balance = null; // 잔금 지급 금액
    this.balanceKr = null;

    this.balanceRate = "100"; // 잔금 지급 비율
    this.contractBondRate = ""; // 계약보증 비율
    this.contractId = null; // 계약 ID

    this.contractPrice = null; // 계약 금액   -
    this.contractPriceKr = null;

    this.paymentDate = null; // 지급예정일

    this.paymentWay = "0"; // 결제 수단

    this.currencyCode = "KRW"; // 화폐 코드 -> 스웨거에는 있으나 erd에는 없음

    this.defactBondRate = ""; // 하자 보증 비율

    this.downPayment = "0"; // 계약금 지급 금액
    this.downPaymentKr = null;

    this.downPaymentRate = "0"; // 계약금 지급 비율

    this.startDate = null; // 시작 날짜
    this.endDate = null; // 종료 날짜

    this.etcContractPrice = null; // 기타 계약금액
    this.etcContractPriceKr = null;

    this.etcDefactRate = ""; // 기타 하자보증 비율
    this.etcDeliveryDate = null; // 기타 납품기한

    this.etcPayment = null; // 기타 지급금액    - 기타 지불방법
    this.etcPaymentKr = null;

    this.etcPaymentRate = null; // 기타 지급비율    - 기타 지불방법
    this.etcPaymentRateKr = null;

    this.middlePayment = "0"; // 중도금 지급 금액
    this.middlePaymentKr = null;

    this.middlePaymentProgress = ""; // 중도금 지급 공정률
    this.middlePaymentRate = "0"; // 중도금 지급 비율

    this.monthlyPayment = null; // 기성금 지급 금액
    this.monthlyPaymentKr = null;

    this.paymentMonth = null; // 기성금 기간 월
    this.paymentMonthCnt = null; // 기성금 기간 회
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      orderCid, //발주번호
      paymentType,
      paymentDescription,
      executionCondition,
      orderPartialPayments, //상세 배열

      advanceRate,
      advancePaymentGuaranteeType,
      advancePaymentSurety,

      contractRate,
      contractGuaranteeType,
      contractSurety,

      defectRate,
      defectGuaranteeType,
      defectSurety,

      penaltyRate,
      warranty,

      //이하 기존데이터 알수 없음

      balance,
      balanceRate,
      contractBondRate,
      contractId,
      contractPrice,
      startDate,
      defactBondRate,
      downPayment,
      downPaymentRate,
      endDate,
      etcContractPrice,
      etcDefactRate,
      etcDeliveryDate,
      etcPayment,
      etcPaymentRate,
      middlePayment,
      middlePaymentProgress,
      middlePaymentRate,
      monthlyPayment,
      paymentMonth,
      paymentMonthCnt,
      specialAgreement,
      currencyCode,
      paymentDate,
      paymentWay,
    } = obj;
    this.orderCid = orderCid;
    this.paymentType = paymentType; //|| 'D';
    this.paymentDescription = paymentDescription; //|| this.paymentType === 'D' ? '분할지급' : '기성지급';
    this.executionCondition = executionCondition; //|| '계약일반조건에 따름';

    if (orderPartialPayments) {
      this.orderPartialPayments = orderPartialPayments.map((item) => {
        const setItem = new PaymentMethodRateLineModel();
        setItem.setData(item);
        return setItem;
      });
    } else {
      this.orderPartialPayments = null;
    }

    //orderPartialPayments, //상세 배열

    this.advanceRate = advanceRate;
    this.advancePaymentGuaranteeType = advancePaymentGuaranteeType;
    this.advancePaymentSurety = advancePaymentSurety;

    this.contractRate = contractRate;
    this.contractGuaranteeType = contractGuaranteeType;
    this.contractSurety = contractSurety;

    this.defectRate = defectRate;
    this.defectGuaranteeType = defectGuaranteeType;
    this.defectSurety = defectSurety;

    this.penaltyRate = penaltyRate;
    this.warranty = warranty;

    if (etcDeliveryDate) this.etcDeliveryDate = stringToDateWithTime(etcDeliveryDate);

    //이하 기존데이터

    if (balance) {
      this.balance = getMoneyToNumber(balance).toString();
    }
    if (balanceRate) this.balanceRate = balanceRate.toString();
    if (contractBondRate) this.contractBondRate = contractBondRate.toString();
    if (contractId) this.contractId = contractId;
    if (contractPrice) this.contractPrice = getMoneyToNumber(contractPrice).toString();
    if (defactBondRate) this.defactBondRate = defactBondRate.toString();
    if (downPayment) this.downPayment = getMoneyToNumber(downPayment).toString();
    if (downPaymentRate) this.downPaymentRate = downPaymentRate.toString();

    if ((startDate, endDate)) {
      this.dates = [stringToDateWithTime(startDate), stringToDateWithTime(endDate)];
    }

    if (paymentDate) {
      this.paymentDate = stringToDateWithTime(paymentDate);
    }

    if (paymentWay) this.paymentWay = paymentWay.toString();

    // if( startDate ) this.startDate = stringToDateWithTime(startDate);
    // if( endDate ) this.endDate = stringToDateWithTime(endDate);

    if (etcContractPrice) this.etcContractPrice = getMoneyToNumber(etcContractPrice).toString();
    if (etcDefactRate) this.etcDefactRate = etcDefactRate.toString();

    if (etcPayment) this.etcPayment = getMoneyToNumber(etcPayment).toString();
    if (etcPaymentRate) this.etcPaymentRate = etcPaymentRate.toString();
    if (middlePayment) this.middlePayment = getMoneyToNumber(middlePayment).toString();
    if (middlePaymentProgress) this.middlePaymentProgress = middlePaymentProgress.toString();
    if (middlePaymentRate) this.middlePaymentRate = middlePaymentRate.toString();
    if (monthlyPayment) this.monthlyPayment = getMoneyToNumber(monthlyPayment).toString();
    if (paymentMonth) this.paymentMonth = paymentMonth.toString();
    if (paymentMonthCnt) this.paymentMonthCnt = paymentMonthCnt.toString();
    if (specialAgreement) this.specialAgreement = specialAgreement;

    if (currencyCode) this.currencyCode = currencyCode;
  }

  getData() {
    const {
      orderCid,
      paymentType,
      paymentDescription,
      executionCondition,
      orderPartialPayments,
      advanceRate,
      advancePaymentGuaranteeType,
      advancePaymentSurety,
      contractRate,
      contractGuaranteeType,
      contractSurety,
      defectRate,
      defectGuaranteeType,
      defectSurety,

      penaltyRate,
      warranty,
    } = this;

    let setOrderPartialPayments = null;
    if (paymentType === "D") {
      setOrderPartialPayments = orderPartialPayments.map((item, index) => {
        let ret = [];
        //대금청구조건이 분할지급일떄
        const itemObj = item.getData();
        itemObj.orderNum = index + 1;
        ret = itemObj;
        return ret;
      });
    } else {
      setOrderPartialPayments = null;
    }
    let obj = {
      orderCid, //발주번호(수정에서 사용)
      paymentType, //대금청구 조건
      paymentDescription, //대금청구 조건text
      executionCondition, //대급지급조건
      orderPartialPayments: setOrderPartialPayments,

      advanceRate, //선급금보증비율
      advancePaymentGuaranteeType, //선급금보증방법
      advancePaymentSurety, //선급금이행증서
      contractRate, //계약보증비율
      contractGuaranteeType, //계약보증비율
      contractSurety, //계약이행증서
      defectRate, //하자보증비율
      defectGuaranteeType, //하자보증방법
      defectSurety, //하자이행증서
      penaltyRate, //지체상금
      warranty, //하자보증기간
    };

    if (this.balance) {
      obj.balance = this.balance;
      obj.balanceKr = getMoneyToKorean(this.balance);
    }

    obj.balanceRate = this.balanceRate;
    obj.contractBondRate = this.contractBondRate;
    obj.contractId = this.contractId;

    if (this.contractPrice) {
      obj.contractPrice = this.contractPrice;
      obj.contractPriceKr = getMoneyToKorean(this.contractPrice);
    }

    obj.startDate = this.startDate;
    obj.endDate = this.endDate;

    obj.paymentDate = this.paymentDate;

    obj.paymentWay = this.paymentWay;

    obj.defactBondRate = this.defactBondRate;

    if (this.downPayment) {
      obj.downPayment = this.downPayment;
      obj.downPaymentKr = getMoneyToKorean(this.downPayment);
    }

    obj.downPaymentRate = this.downPaymentRate;

    if (this.etcContractPrice) {
      obj.etcContractPrice = this.etcContractPrice;
      obj.etcContractPriceKr = getMoneyToKorean(this.etcContractPrice);
    }

    obj.etcDefactRate = this.etcDefactRate;

    obj.etcDeliveryDate = this.etcDeliveryDate ? dateToStringWithTime(this.etcDeliveryDate) : ""; //납품기한

    //obj.etcDeliveryDate = this.etcDeliveryDate ? dateToStringWithTime(this.etcDeliveryDate) : '';
    if (this.etcPayment) {
      obj.etcPayment = this.etcPayment;
      obj.etcPaymentKr = getMoneyToKorean(this.etcPayment);
    }

    obj.etcPaymentRate = this.etcPaymentRate;

    if (this.middlePayment) {
      obj.middlePayment = this.middlePayment;
      obj.middlePaymentKr = getMoneyToKorean(this.middlePayment);
    }

    obj.middlePaymentProgress = this.middlePaymentProgress;
    obj.middlePaymentRate = this.middlePaymentRate;

    if (this.monthlyPayment) {
      obj.monthlyPayment = this.monthlyPayment;
      obj.monthlyPaymentKr = getMoneyToKorean(this.middlePayment);
    }

    obj.paymentMonth = this.paymentMonth;

    obj.paymentMonthCnt = this.paymentMonthCnt;
    obj.specialAgreement = this.specialAgreement;

    obj.currencyCode = this.currencyCode;

    let startDate,
      endDate = "";

    if (this.dates && this.dates.length === 2) {
      startDate = dateToStringWithTime(this.dates[0]);
      endDate = dateToStringWithTime(this.dates[1]);
    }

    obj.startDate = startDate;
    obj.endDate = endDate;

    return {
      orderPayment: obj,
    };
  }

  getAlertMessage() {
    if (!this.paymentDescription) return "대급청구조건을 확인해주세요.";
    if (!this.executionCondition) return "대급지급조건을 확인해주세요.";
    if (
      !this.advancePaymentGuaranteeType ||
      !this.contractGuaranteeType ||
      !this.defectGuaranteeType
    )
      return "보증방법을 확인해주세요";
    if (!this.penaltyRate) return "지체상금을 확인해주세요.";
    if (!this.warranty) return "	하자보증기간을 확인해주세요.";

    return null;
  }
}
