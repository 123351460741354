<template>
  <TableViewWithTitle title="협력사 정보">
    <template v-slot:headRight>
      <div class="group_form">
        <button
          v-if="contractType !== 'estimate' && !String(contractId)"
          type="button"
          class="btn_secondary btn_medium"
          @click="onClickPopPartnerSelect"
        >
          업체 선택
        </button>
      </div>
    </template>
    <template v-slot:body>
      <tr>
        <th>업체명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <button
            v-if="model.userNum"
            class="link_subject"
            @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${model.userNum}`)"
          >
            {{ model.companyName || "" }}
          </button>
          <span v-else>{{ model.companyName }}</span>
        </td>
        <th>대표이사명<span class="ico_purchase ico_star">필수항목</span></th>
        <td>{{ model.ceo }}</td>
      </tr>
      <tr>
        <th>사업자번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          {{ model.corporateNum }}
          <!-- <Input :isDisabled="true" :value.sync="model.corporateNum" /> -->
        </td>
      </tr>
      <tr>
        <th>본사 주소<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <InputAddress
            :zipcode.sync="model.zipcode"
            :address.sync="model.address"
            :address2.sync="model.address2"
            addressTip="* 사업자등록증 본사 주소지 입력"
          />
        </td>
      </tr>
      <!-- 투찰금액 위치 변경 -->
      <!-- <tr> 
        <th>사업자번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td>{{ model && model.corporateNum }}</td>
       <th>투찰금액<span class="ico_purchase ico_star">필수항목</span></th>
        <td>{{ model.price }}</td> 
      </tr> -->
      <!-- <tr>
        <th>견적서</th>
        <td>
          <FileView :dataList="model.estimateFileList" />
        </td>
        <th>제안서</th>
        <td>
          <FileView :dataList="model.proposalFileList" />
        </td>
      </tr> -->
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileView from "@/components/common/file/FileView";
import InputAddress from "@/components/common/input/InputAddress";

export default {
  name: "SupplyPartnerInfo",
  components: {
    TableViewWithTitle,
    FileView,
    InputAddress,
  },
  props: {
    model: Object,
    contractType: String,
    contractId: String,
  },
  methods: {
    onClickPopPartnerSelect() {
      this.$emit("onClickPopPartnerSelect");
    },
  },
};
</script>
