<template>
  <TableViewWithTitle title="파트너사 담당자 정보">
    <template v-slot:body>
      <tr>
        <th>파트너사 담당자<span class="ico_purchase ico_star">필수항목</span></th>

        <td colspan="3">
          {{ model.name }}
        </td>
      </tr>
      <tr>
        <th>휴대전화<span class="ico_purchase ico_star">필수항목</span></th>

        <td>
          {{ model.mobile.getTextFull() }}
        </td>
        <th>이메일<span class="ico_purchase ico_star">필수항목</span></th>

        <td class="td_text">
          {{ model.email.getTextFull() }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Input from "@/components/common/input/Input";
import InputPhone from "@/components/common/input/InputPhone";
import InputEmail from "@/components/common/input/InputEmail";

import SupplyPartnerManagerInfoModel from "./SupplyPartnerManagerInfoModel";
import LocalStorageManager from "@/LocalStorageManager";

export default {
  name: "SupplyPartnerManagerInfo",
  components: {
    TableViewWithTitle,
    Input,
    InputPhone,
    InputEmail,
  },
  props: {
    model: SupplyPartnerManagerInfoModel,
  },
};
</script>
