import EmailModel from "@/models/EmailModel";
import PhoneModel from "@/models/PhoneModel";

class SupplyPartnerManagerInfoModel {
  constructor() {
    this.name = "";
    this.mobile = new PhoneModel();
    this.email = new EmailModel();
  }

  setData(objRaw) {
    const { name, mobile, email } = objRaw;

    if (name) this.name = name;
    if (mobile) {
      this.mobile.setDataByString(mobile);
    }
    if (email) {
      this.email.setDataByString(email);
    }
  }

  getData() {
    const obj = {
      name: this.name,
    };

    if (this.mobile && this.mobile.getIsValid()) {
      obj.mobile = this.mobile.getTextFull();
    }

    if (this.email && this.email.getIsValid()) {
      obj.email = this.email.getTextFull();
    }

    return obj;
  }

  getAlertMessage() {
    // const defaultAlertMessage = '파트너사 담당자 정보를 확인해주세요.';

    if (!this.name) return "파트너사 담당자명을 확인해주세요.";
    if (!this.mobile.getIsValid()) return "파트너사 담당자 휴대전화를 확인해주세요.";
    if (!this.email.getIsValid()) return "파트너사 담당자 이메일을 확인해주세요.";

    return null;
  }
}

export default SupplyPartnerManagerInfoModel;
