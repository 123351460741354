<template>
  <TableView>
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col style="width: 428px" />
      <col style="width: 620px" />
    </template>
    <template v-slot:body>
      <tr>
        <th>제안단계 견적가</th>
        <td>
          <InputAutoMoney :inputClass="'align_right'" :value.sync="supplyInfoModel.estimatePrice" />
        </td>
        <td rowspan="2" class="list_total">
          <dl class="list_total">
            <template v-if="type === 'renewal'">
              <dt>기 검수금액</dt>
              <dd>
                {{ totalInspectAmount | currency }}
              </dd>
            </template>
            <dt>총 절감금액</dt>
            <dd>
              {{ supplyInfoModel.totalSavingAmount | currency }}
              <span class="txt_util">부가세(VAT) 별도</span>
            </dd>
            <dt>총 계약금액</dt>
            <dd>
              {{ supplyInfoModel.totalContractAmount | currency }}
              <span class="txt_util">부가세(VAT) 별도</span>
            </dd>
            <!-- <dt>총 부가세</dt>
            <dd>
              {{ supplyInfoModel.totalVat | currency }}
              <span class="txt_util">부가세(VAT) 별도</span>
            </dd> -->
          </dl>
        </td>
      </tr>
      <tr>
        <th>제안단계 견적서</th>
        <td>
          <FileWrite
            :dataList.sync="attachFile.estimateFile"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
      </tr>
    </template>
  </TableView>
</template>

<script>
import Board from "@/components/common/board/Board";
import TableView from "@/components/shared/tableView/TableView";
import Input from "@/components/common/input/Input";
import InputAutoMoney from "@/components/common/input/InputAutoMoney";
import FileWrite from "@/components/common/file/FileWrite";

//import SupplyFinalPriceModel from './SupplyFinalPriceModel';

export default {
  name: "SupplyFinalPrice",
  components: {
    Board,
    TableView,
    Input,
    InputAutoMoney,
    FileWrite,
  },
  props: {
    type: String,
    totalInspectAmount: Number,
    supplyInfoModel: Object,
    attachFile: Object,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {
    fileUploaded(files) {
      this.attachFile.estimateFile = this.attachFile.estimateFile.concat(files);
    },
    fileRemoved(itemToRemove) {
      this.attachFile.estimateFile = this.attachFile.estimateFile.filter(
        (item) => item !== itemToRemove,
      );
    },
  },
};
</script>
