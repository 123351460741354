import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

class AnnounceInfoModel {
  constructor() {
    //postCid

    this.title = "";

    this.startDate = null;
    this.endDate = null;

    this.category0 = null;
    this.category1 = null;
    this.category2 = null;

    this.category = ""; // ex) 인프라,투자,네트워크장비류
    this.price = ""; // 투찰금액
    this.currency = ""; // 투찰금액 보여주기위해 currency도 공고모델에..
  }

  setData(objRaw) {
    const { title, startDate, endDate, cate1, cate2, cate3, category, postTender } = objRaw;

    if (title) this.title = title;

    if (startDate) this.startDate = stringToDateWithTime(startDate);
    if (endDate) this.endDate = stringToDateWithTime(endDate);

    this.category0 = cate1 || null;
    this.category1 = cate2 || null;
    this.category2 = cate3 || null;

    this.category = category || "";
  }

  getData() {
    const obj = {
      title: this.title,
    };

    if (this.startDate) {
      obj.startDate = dateToStringWithTime(this.startDate);
    }
    if (this.endDate) {
      obj.endDate = dateToStringWithTime(this.endDate);
    }

    // 필수값 대분류
    const cate = this.category2 || this.category1 || this.category0;
    if (cate) {
      obj.cateNum = cate;
    }

    return obj;
  }

  getAlertMessage(flag = false) {
    if (flag) {
      if (!this.startDate) return "입찰 시작일을 확인해주세요.";
      if (!this.title) return "입찰공고명을 확인해주세요.";
      if (!this.endDate) return "입찰 마감일을 확인해주세요.";
      if (!this.category0) return "입찰 정보 분류를 확인해주세요."; // 필수 값 대분류.
      if (!this.price) return "입찰 정보 투찰금액을 확인해주세요.";
    }

    return null;
  }
}

export default AnnounceInfoModel;
