<template>
  <TableViewWithTitle title="구매신청서 연결">
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>구매신청서 연결<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          <ConnectEdit :dataList="connectDataList" @onClickRemove="onClickRemove" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import ConnectEdit from "@/components/common/connect/ConnectEdit";

export default {
  components: {
    TableViewWithTitle,
    ConnectEdit,
  },
  props: {
    connectDataList: Array,
  },
  methods: {
    onClickRemove(item) {
      this.$emit("onClickRemove", item);
    },
  },
};
</script>
