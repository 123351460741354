import LocalStorageManager from "@/LocalStorageManager";
//유저데이터 꼽아 넣고
class ManagerInfoModel {
  constructor() {
    // this.objRaw = null;
    this.userData = LocalStorageManager.shared.getUserData();
    this.managerId = this.userData.username;
    this.managerName = this.userData.name;
    this.managerNum = this.userData.managerNum;
    this.managerEmail = this.userData.email;
    this.managerTel = this.userData.tel;
    this.orderManagerList = [];
    this.pManager = {
      code: "P",
      email: this.managerEmail,
      loginId: this.managerId,
      name: this.managerName,
      tel: this.managerTel,
      personNum: "",
    };
    this.bManager = {
      code: "B",
      email: "",
      loginId: "",
      name: "",
      tel: "",
      personNum: "",
    };
    this.iManager = {
      code: "I",
      email: "",
      loginId: "",
      name: "",
      tel: "",
      personNum: "",
    };
  }

  setData(arr) {
    // const { managerId, managerName, managerNum, managerEmail, managerTel, orderManagerList } = obj;
    // this.managerId = managerId;
    // this.managerName = managerName;
    // this.managerNum = managerNum;
    // this.managerEmail = managerEmail;
    // this.managerTel = managerTel;
    // this.orderManagerList = orderManagerList;
    // this.pManager = {
    //   code: 'P',
    //   email: managerEmail,
    //   loginId: managerId,
    //   name: managerName,
    //   tel: managerTel,
    //   personNum: '',
    // };
    //this.setManager(orderManagerList);
    const pManager = arr.find((item) => item.code === "P");
    this.pManager = pManager;
    const bManager = arr.find((item) => item.code === "B");
    this.bManager = bManager;
    const iManager = arr.find((item) => item.code === "I");
    this.iManager = iManager;
  }

  setBManager(obj) {
    const { requesterId, requesterName, requesterTel, requesterEmail, requesterPersonNum } = obj;
    const bManager = {
      code: "B",
      email: requesterEmail,
      loginId: requesterId,
      name: requesterName,
      tel: requesterTel,
      personNum: requesterPersonNum || "",
    };
    // const pManager = this.orderManagerList.find((item) => item.code === 'P');
    // this.orderManagerList = [{ ...pManager }, { ...bManager }];

    this.bManager = bManager;
  }
  setIManager(obj) {
    const { requesterId, requesterName, requesterTel, requesterEmail, requesterPersonNum } = obj;
    const iManager = {
      code: "I",
      email: requesterEmail,
      loginId: requesterId,
      name: requesterName,
      tel: requesterTel,
      personNum: requesterPersonNum,
    };
    // const pManager = this.orderManagerList.find((item) => item.code === 'P');
    // this.orderManagerList = [{ ...pManager }, { ...iManager }];

    this.iManager = iManager;
  }

  getData() {
    const {
      // managerId,
      // managerName,
      // managerNum,
      // managerEmail,
      // managerTel,
      //orderManagerList,
      pManager,
      bManager,
      iManager,
    } = this;
    let obj = {
      // managerId,
      // managerName,
      // managerNum,
      // managerEmail,
      // managerTel,
      //orderManagerList,
      pManager,
      bManager,
      iManager,
    };

    return obj;
  }

  getAlertMessage() {
    return null;
  }
}

export default ManagerInfoModel;
