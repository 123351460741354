<template>
  <TableViewWithTitle title="첨부 파일">
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>견적서</th>
        <td>
          <FileWrite
            :canMultiple="false"
            :dataList="attachFileList"
            @fileUploaded="fileUploaded"
            @fileRemoved="fileRemoved"
          />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import FileWrite from "@/components/common/file/FileWrite";

export default {
  name: "FileUpload",
  components: {
    TableViewWithTitle,
    FileWrite,
  },
  props: {
    attachFileList: Array,
  },
  methods: {
    fileUploaded(file) {
      //const arr = this.attachFileList.concat( files );

      this.$emit("update:attachFileList", file);
    },
    fileRemoved(itemToRemove) {
      const arr = this.attachFileList.filter((item) => item !== itemToRemove);

      this.$emit("update:attachFileList", arr);
    },
  },
};
</script>
