<template>
  <TableViewWithTitle title="입찰공고 정보">
    <template v-if="showPostCid" v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />공고를 불러온 경우 기타 정보를 수정할 수 공고 연결
        해제 시 내용이 초기화됩니다.
      </p>
    </template>
    <template v-else v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />입찰공고 정보를 입력하지 않으면 자동으로
        생성됩니다.
      </p>
    </template>
    <template v-if="showPostCid" v-slot:headRight>
      <div class="group_form">
        <p v-if="selectDiffPostCid" class="desc_alert tc_red">
          발주서와 다른 구매요청건의 입찰공고를 선택하셨습니다.
        </p>
        <button type="button" class="btn_secondary btn_medium" @click="onClickDisconnect">
          공고 연결 해제
        </button>
      </div>
    </template>
    <template v-if="showPostCid" v-slot:body>
      <tr>
        <th>입찰 공고번호<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          <div class="group_form">
            <span class="txt_view_l w270">{{ postCid }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <th>입찰 공고명<span class="ico_purchase ico_star">필수항목</span></th>
        <td class="td_text">
          <router-link :to="toPath" class="link_subject" target="_blank" rel="opener">
            {{ model.title }}
          </router-link>
        </td>
        <th>입찰 시작일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          {{ startDate }}
        </td>
      </tr>
      <tr>
        <th>투찰금액<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          {{ priceWithCurrency }}
        </td>
        <th>입찰 마감일<span class="ico_purchase ico_star">필수항목</span></th>
        <td>
          {{ endDate }}
        </td>
      </tr>
      <tr>
        <th>분류<span class="ico_purchase ico_star">필수항목</span></th>
        <td colspan="3">
          {{ category }}
        </td>
      </tr>
    </template>
    <template v-else v-slot:body>
      <tr>
        <th>입찰 공고명</th>
        <td class="td_text">
          {{ model.title }}
        </td>
        <th>입찰 시작일</th>
        <td>
          {{ startDate }}
        </td>
      </tr>
      <tr>
        <th>투찰금액</th>
        <td>{{ priceWithCurrency }}</td>
        <th>입찰 마감일</th>
        <td>
          {{ endDate }}
        </td>
      </tr>
      <tr>
        <th>분류</th>
        <td colspan="3">
          {{ category }}
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";

import AnnounceInfoModel from "./AnnounceInfoModel";

import { dateToStringWithTimeHasDiv } from "@/utils/dateUtils";
import { getCategoryDepthText, getCountWithCurrency } from "@/utils/spUtils";

export default {
  name: "AnnounceInfo",
  components: {
    TableViewWithTitle,
  },
  props: {
    postCid: String,
    model: AnnounceInfoModel,
    selectDiffPostCid: Boolean,
    showPostCid: Boolean,
  },
  computed: {
    category() {
      if (!this.model.category) return "";
      return getCategoryDepthText(this.model.category);
    },
    toPath() {
      const path = `${this.$routerPath.ANNOUNCE_VIEW}/${this.postCid}`;
      return path;
    },
    startDate() {
      if (!this.model.startDate) return "";
      return dateToStringWithTimeHasDiv(this.model.startDate);
    },
    endDate() {
      if (!this.model.endDate) return "";
      return dateToStringWithTimeHasDiv(this.model.endDate);
    },
    priceWithCurrency() {
      if (!this.model.price) return "";

      return getCountWithCurrency(this.model.price, "");
    },
  },
  methods: {
    onClickDisconnect() {
      this.$emit("onClickDisconnect");
    },
  },
};
</script>
