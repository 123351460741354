<template>
  <TableViewWithTitle title="발주 메모">
    <template v-slot:headLeft>
      <p class="desc_tip tc_red">
        <span class="ico_purchase ico_exclam_r" />해당 메모는 발주서에 노출되지 않으며, 보여집니다.
      </p>
    </template>
    <template v-slot:colgroup>
      <col style="width: 192px" />
      <col />
    </template>
    <template v-slot:body>
      <tr>
        <th>발주 메모</th>
        <td class="h96">
          <Textarea :value.sync="memoInput" />
        </td>
      </tr>
    </template>
  </TableViewWithTitle>
</template>

<script>
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Textarea from "@/components/common/textarea/Textarea";

export default {
  name: "OrderMemo",
  components: {
    TableViewWithTitle,
    Textarea,
  },
  props: {
    memo: String,
  },
  computed: {
    memoInput: {
      get() {
        return this.memo;
      },
      set(value) {
        this.$emit("update:memo", value);
      },
    },
  },
};
</script>
